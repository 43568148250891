<script setup>
import Icon from '@js/Components/Icon/Icon.vue'
import { ref } from 'vue'
import CustomTransitionExpand from '@js/Components/Transitions/CustomTransitionExpand.vue'

const isOpen = ref(false)
</script>
<template>
  <div
    class="flex items-center transition-all hover:cursor-pointer"
    @click="isOpen = !isOpen"
  >
    <span class="text-base  font-normal"><slot name="title" /></span>
    <span class="pl-2">
      <Icon v-show="!isOpen" name="chevron-down-color" />
      <Icon v-show="isOpen" name="chevron-up-color" />
    </span>
  </div>

  <CustomTransitionExpand>
    <div v-show="isOpen" class="text-left font-normal text-base mt-4">
      <slot />
    </div>
  </CustomTransitionExpand>
</template>
