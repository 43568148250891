<script setup>
import MyRentbirdCard from '@js/Components/MyRentbird/MyRentbirdCard/MyRentbirdCard.vue'
import { useUser, useSpeedScore } from '@js/App/Api/User/User'
import Zap from '@images/icons/zap.svg'
import Image from '@js/Components/Image/Image.vue'
import InfoMessageBox from '@js/Components/InfoMessageBox/InfoMessageBox.vue'
import Light from '@images/icons/light.png'
import { ref, computed, onMounted } from 'vue'
import { trans } from '@js/App/Plugins/Translations'
import DarkPurpleModalOrBottom from '@js/Components/Modals/DarkPurpleModalOrBottom.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { router } from '@inertiajs/vue3'

const { user } = useUser()
const { speedScore, reloadSpeedScore } = useSpeedScore()
const hasActiveSubscription = ref(user.value.hasActiveSubscription)
const moreInfo = ref(false)

const calculateBarColor = (category) => {
  if (category === 'NODATA') { return 'bg-gray-400 text-gray-400' }
  if (category === 'FASTEST') { return 'bg-green-500 text-green-500' }
  if (category === 'FAST') { return 'bg-green-200 text-green-200' }
  if (category === 'SLOW') { return 'bg-warning text-warning' }
  return 'bg-danger-soft text-danger-soft'
}

const calculateBarText = (category) => {
  if (category === 'NODATA') { return trans('SpeedScoreCard.NoData') }
  if (category === 'FASTEST') { return trans('SpeedScoreCard.Fastest') }
  if (category === 'FAST') { return trans('SpeedScoreCard.Fast') }
  if (category === 'SLOW') { return trans('SpeedScoreCard.Slow') }
  return trans('SpeedScoreCard.Slowest')
}

const openingBarText = computed(() => {
  return calculateBarText(speedScore.value.click_time_category)
})
const openingBarColor = computed(() => {
  return calculateBarColor(speedScore.value.click_time_category)
})

const respondingBarText = computed(() => {
  return calculateBarText(speedScore.value.reaction_time_category)
})
const respondingBarColor = computed(() => {
  return calculateBarColor(speedScore.value.reaction_time_category)
})

const openingPercentage = computed(() => {
  const clickTime = speedScore.value.click_time
  const maxTime = 3600

  if (clickTime > maxTime || clickTime === null) {
    return 0
  }

  return Math.max(0, Math.min(((maxTime - clickTime) / maxTime) * 100, 100)).toFixed(2)
})

const respondingPercentage = computed(() => {
  const reactionTime = speedScore.value.reaction_time
  const maxTime = 3900

  if (reactionTime > maxTime || reactionTime === null) {
    return 0
  }

  return Math.max(0, Math.min(((maxTime - reactionTime) / maxTime) * 100, 100)).toFixed(2)
})

const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600).toString().padStart(2, '0')
  const minutes = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0')
  const secs = (seconds % 60).toString().padStart(2, '0')
  return `${hours}:${minutes}:${secs}`
}

onMounted(() => {
  router.on('finish', () => {
    reloadSpeedScore()
  })
})

</script>

<template>
  <MyRentbirdCard :can-expand-on-mobile="false" :inactive-on-free-account="!hasActiveSubscription">
    <template #title>
      <div class="flex items-center">
        <Image :src="Zap" class="pr-2" />
        <div>{{ $trans('SpeedScoreCard.Title') }}</div>
      </div>
    </template>

    <template #title-desktop-right>
      <span
        class="text-sm text-blue-base hover:cursor-pointer hover:underline transition-all relative top-[1px]"
        @click="moreInfo = true"
      >
        {{ $trans('MoreInfo') }}
      </span>
    </template>

    <template #title-mobile-right>
      <span
        class="text-sm text-blue-base hover:cursor-pointer hover:underline transition-all relative top-[1px]"
        @click="moreInfo = true"
      >
        {{ $trans('MoreInfo') }}
      </span>
    </template>

    <template #default>
      <span class="my-2">{{ $trans('SpeedScoreCard.Body') }}</span>
      <hr class="mt-2 mb-4">
      <InfoMessageBox v-if="speedScore.click_time === null && speedScore.reaction_time === null" :image="Light" color="gray">
        {{ $trans('SpeedScoreCard.NoResultsNotice') }}
      </InfoMessageBox>

      <div v-if="speedScore.click_time !== null || speedScore.reaction_time !== null">
        <div class="mb-3">
          <div class="flex items-center justify-between">
            <span>{{ $trans('SpeedScoreCard.OpenNotification') }}</span>
            <span class="!bg-white text-sm font-medium" :class="openingBarColor">{{ openingBarText }}</span>
          </div>
          <div class="flex items-center space-x-4 mt-1">
            <span class="text-xl font-bold">{{ formatTime(speedScore.click_time) }}{{ speedScore.click_time >= 3600 ? '+' : '' }}</span>
            <div class="relative w-full bg-gray-200 rounded-full h-4">
              <div
                class="absolute top-0 left-0 h-4 rounded-full"
                :class="openingBarColor"
                :style="{ width: (openingPercentage) + '%' }"
              />
            </div>
          </div>
        </div>

        <div class="mb-3">
          <div class="flex items-center justify-between">
            <span>{{ $trans('SpeedScoreCard.OpenReaction') }}</span>
            <span class="!bg-white text-sm font-medium" :class="respondingBarColor">{{ respondingBarText }}</span>
          </div>
          <div class="flex items-center space-x-4 mt-1">
            <span class="text-xl font-bold">{{ formatTime(speedScore.reaction_time) }}{{ speedScore.reaction_time >= 3900 ? '+' : '' }}</span>
            <div class="relative w-full bg-gray-200 rounded-full h-4">
              <div
                class="absolute top-0 left-0 h-4 rounded-full"
                :class="respondingBarColor"
                :style="{ width: (respondingPercentage) + '%' }"
              />
            </div>
          </div>
        </div>
      </div>

      <InfoMessageBox v-if="speedScore.click_time !== null && speedScore.reaction_time === null" :image="Light" color="gray">
        {{ $trans('SpeedScoreCard.NoReactionsNotice') }}
      </InfoMessageBox>

      <DarkPurpleModalOrBottom :is-open="moreInfo" :closeable="true">
        <h4 class="text-white text-xl font-medium">
          {{ $trans('SpeedScoreCard.Modal.Title') }}
        </h4>
        <p class="mt-[6px] text-base" v-html="$trans('SpeedScoreCard.Modal.Body')" />
        <Button
          target="_blank"
          :full-width-on-mobile="true"
          :full-width-on-desktop="true"
          icon-right="cross"
          class="mt-[32px]"
          @click="moreInfo = false;"
        >
          <span>{{ $trans('Common.Close') }}</span>
        </Button>
      </darkpurplemodalorbottom>
    </template>
  </MyRentbirdCard>
</template>
