<script setup>
import MyRentbirdBackButton from '@js/Components/MyRentbird/MyRentbirdBackButton/MyRentbirdBackButton.vue'
import MyRentbirdCard from '@js/Components/MyRentbird/MyRentbirdCard/MyRentbirdCard.vue'
import { usePage } from '@inertiajs/vue3'
import HouseCardMatchHistoryNew from '@js/Components/HouseCards/HouseCardMatchHistoryNew/HouseCardMatchHistoryNew.vue'
import InfoMessageBox from '@js/Components/InfoMessageBox/InfoMessageBox.vue'
import WarningTriangle from '@images/icons/warning-triangle.svg'
import Button from '@js/Components/Forms/Button/Button.vue'
import TextArea from '@js/Components/Forms/TextArea/TextArea.vue'
import Checkbox from '@js/Components/Forms/Checkbox/Checkbox.vue'
import { ref, watch, computed } from 'vue'
import { doPostRequest } from '@js/App/Api/Infra/ApiClient'
import { copyTextToClipboard } from '@js/App/Utils/Clipboard'
import MyRentbirdLayout from '@js/Components/MyRentbird/MyRentbirdLayout/MyRentbirdLayout.vue'

import SearchProfileCardLabel from '@js/Components/SearchProfileCard/SearchProfileCardLabel.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import { capitalizeFirstLetter } from '@js/App/Utils/StringFormatting'
import SearchProfileCard from '@js/Components/SearchProfileCard/SearchProfileCard.vue'
import { getSearchProfileFromDto } from '@js/App/Stores/SearchProfileStore'
import TextInput from '@js/Components/Forms/TextInput/TextInput.vue'
import { useUser } from '@js/App/Api/User/User'

import DarkPurpleModalOrBottom from '@js/Components/Modals/DarkPurpleModalOrBottom.vue'
import { reactToHouse, unreactToHouse } from '@js/App/Api/User/Houses'
import { useMainStore } from '@js/App/Stores/MainStore'
import { trans } from '@js/App/Plugins/Translations'
import PricingCardSimpleNew from '@js/Components/PricingCard/PricingCardSimpleNew/PricingCardSimpleNew.vue'
import ThreeSmallUspsBlue from '@js/Components/Marketing/ThreeSmallUspsBlue/ThreeSmallUspsBlue.vue'
import { onClickPricingCardPayButton } from '@js/App/Api/Payments'

const { user } = useUser()
const page = usePage()
const house = computed(() => page.props.house)
let searchProfile = null
if (user.value.hasActiveSubscription) {
  searchProfile = getSearchProfileFromDto(page.props.search_profile)
}

const houseInteractivityStats = computed(() => page.props.house_interactivity_stats)
const rentalAgentProfile = page.props.rental_agent_profile
const responseLetter = page.props.response_letter?.replaceAll('\\n', '\n')
const mainStore = useMainStore()

const showWarningExternalWebsite = ref(!!page.props.show_warning_external_website)

watch(showWarningExternalWebsite, () => {
  doPostRequest('/api/user/show-warning-external-website', {
    show_warning_external_website: !!showWarningExternalWebsite.value
  })
})

const showCopySuccessMessage = ref(false)
const onClickCopyLetter = async () => {
  await copyTextToClipboard(responseLetter)

  showCopySuccessMessage.value = true
}

const showCopySharingSuccessMessage = ref(false)
const onClickSharingButton = async () => {
  await copyTextToClipboard(house.value.url)

  showCopySharingSuccessMessage.value = true
  mainStore.setSuccesToastText(trans('LinkCopied'))
}

const confirmRedirect = ref(false)

const openHouseExternally = () => {
  window.open(house.value.detail_url, '_blank')

  if (!house.value.is_reacted) {
    confirmReacted.value = true
  } else if (house.value.feedback_by_user === null) {
    confirmMatchCorrect.value = true
  }
}

const redirect = () => {
  if (showWarningExternalWebsite.value) {
    confirmRedirect.value = true
  } else {
    openHouseExternally()
  }
}

const confirmReacted = ref(false)
const react = (reacted) => {
  confirmReacted.value = false

  if (reacted) {
    reactToHouse(house.value.id)
    mainStore.setSuccesToastText(trans('HousePage.ReactedModal.Toast'))
  } else {
    unreactToHouse(house.value.id)
  }

  // if (house.value.feedback_by_user === null) {
  //   confirmMatchCorrect.value = true
  // }
}

const confirmMatchCorrect = ref(false)

const correctMatch = ref({
  feedback: house.value.feedback_by_user?.feedback ?? null,
  comment: house.value.feedback_by_user?.comment ?? ''
})

const feedbackHasBeenSend = ref(false)

const sendFeedback = () => {
  doPostRequest('/api/house-feedback', {
    house_id: house.value.id,
    feedback: correctMatch.value.feedback,
    comment: correctMatch.value.comment
  })

  mainStore.setSuccesToastText(trans('HousePage.CorrectMatch.FeedbackSend'))
  confirmMatchCorrect.value = false
  feedbackHasBeenSend.value = true
}

</script>
<template>
  <MyRentbirdLayout>
    <MyRentbirdBackButton />

    <div class="flex flex-wrap">
      <MyRentbirdCard :can-expand-on-mobile="false" class="mt-4 w-full lg:w-1/2">
        <template #default>
          <div class="-mt-4 flex flex-col md:flex-row md:items-center md:justify-between">
            <div class="flex-1">
              <h4 class="text-lg font-medium break-all">
                {{ house.name }}
              </h4>
              <p class="mb-2">
                {{ house.created_at_formatted_diff }}
                <svg
                  class="inline"
                  xmlns="http://www.w3.org/2000/svg"
                  width="4"
                  height="4"
                  viewBox="0 0 4 4"
                  fill="none"
                >
                  <circle cx="2" cy="2" r="2" fill="#3A3B3B" />
                </svg>
                {{ house.rental_agent_name }}
              </p>
            </div>
            <div>
              <div class="flex flex-wrap">
                <SearchProfileCardLabel class="mr-[4px] mt-[4px] rounded-full" color="blue">
                  <div class="flex">
                    <Icon name="eye" icon-width="w-[20px] h-[20px] " class="mr-[4px] relative top-[1px] text-blue-base" />
                    <span class="text-sm text-purple-900">{{ houseInteractivityStats.viewed }}</span>
                  </div>
                </SearchProfileCardLabel>
                <SearchProfileCardLabel class="mr-[4px] mt-[4px] rounded-full" color="blue">
                  <div class="flex">
                    <Icon name="heart-light" icon-width="w-[20px] h-[20px] " class="mr-[4px] relative top-[1px] text-blue-base" />
                    <span class="text-sm text-purple-900">{{ houseInteractivityStats.bookmarked }}</span>
                  </div>
                </SearchProfileCardLabel>
                <SearchProfileCardLabel class="mr-[4px] mt-[4px] rounded-full" color="blue">
                  <div class="flex">
                    <Icon name="edit-3" icon-width="w-[20px] h-[20px] " class="mr-[4px] relative top-[1px] text-blue-base" />
                    <span class="text-sm text-purple-900">{{ houseInteractivityStats.reacted }}</span>
                  </div>
                </SearchProfileCardLabel>
              </div>
            </div>
          </div>

          <HouseCardMatchHistoryNew
            class="my-6 lg:my-4"
            :house-id="house.id"
            :bedrooms="house.bedrooms"
            :found-at-formatted="house.created_at_formatted_diff"
            :city="house.city"
            :name="house.name"
            :area="house.area"
            :rent="house.rent"
            :image="house.image_url"
            :rental-agent="house.rental_agent_name"
            :url="house.detail_url"
            :is-bookmarked="house.is_bookmarked"
            :is-reacted="house.is_reacted"
            :rental-chance="house.rental_chance"
          />

          <div class="flex flex-wrap">
            <div
              :class="{
                'mb-6 w-full lg:mb-0': true,
                'lg:order-last' : user.hasActiveSubscription,
              }"
            >
              <div v-if="user.hasActiveSubscription" class="flex flex-col lg:flex-row lg:justify-between gap-2">
                <Button
                  v-if="user.hasCreatedResponseLetter"
                  :outline="true"
                  :full-width-on-mobile="true"
                  :full-width-on-desktop="true"
                  :icon-right="!showCopySuccessMessage ? 'copy' : 'check'"
                  class="mb-2 w-full"
                  @click="onClickCopyLetter"
                >
                  <span v-if="!showCopySuccessMessage">{{ $trans('HousePage.CopyLetter') }}</span>
                  <span v-else>{{ $trans('Common.Copied') }}</span>
                </Button>
                <Button
                  v-else
                  tag="a"
                  :href="$getLocalizedUrl('my-rentbird.response-letter')"
                  :outline="true"
                  :full-width-on-mobile="true"
                  :full-width-on-desktop="true"
                  icon-right="plus-square"
                  class="mb-2 w-full"
                >
                  {{ $trans('HousePage.CreateResponseLetter') }}
                </Button>

                <Button
                  class="w-full"
                  :full-width-on-mobile="true"
                  :full-width-on-desktop="true"
                  icon-right="external"
                  @click="redirect()"
                >
                  {{ $trans('HousePage.ViewHouse') }}
                </Button>
              </div>

              <InfoMessageBox
                v-if="!user.hasActiveSubscription"
                class="mt-4"
                color="red"
                text-color="red"
                :image="WarningTriangle"
              >
                <div
                  v-html="$trans('HousePage.NoSubscriptionYet')
                  "
                />
              </InfoMessageBox>

              <InfoMessageBox
                v-if="!user.hasCreatedResponseLetter && user.hasActiveSubscription"
                class="mt-4"
                color="red"
                text-color="black"
                :image="WarningTriangle"
              >
                <div
                  v-html="$trans('HousePage.NoResponseLetterYet', {
                    url: $getLocalizedUrl('my-rentbird.response-letter')
                  })
                  "
                />
              </InfoMessageBox>
            </div>
            <div>
              <InfoMessageBox
                v-if="rentalAgentProfile?.requires_payment_amount > 0"
                class=""
                color="yellow"
                text-color="black"
                :image="WarningTriangle"
              >
                <div
                  v-html="
                    $trans('HousePage.PaidWebsiteInfoBox', {
                      url: $getLocalizedUrl('my-rentbird.preferences')
                    })
                  "
                />
              </InfoMessageBox>

              <InfoMessageBox
                v-if="rentalAgentProfile?.individuals_place_houses"
                :class="{
                  'mt-4': rentalAgentProfile?.requires_payment_amount > 0,
                }"
                color="yellow"
                text-color="black"
                :image="WarningTriangle"
              >
                <div v-html="$trans('HousePage.IndividualsPlaceHousesInfoBox')" />
              </InfoMessageBox>

              <div class="mt-4 lg:mt-6 lg:mb-6">
                <h4 v-if="house.house_type || house.has_balcony === 'positive' || house.is_sociale_lootwoning === 1 || house.is_on_paid_website === 1 || house.is_for_pets === 'positive' || house.is_for_3_or_more_sharers === 'positive' || house.is_for_students === 'positive' || house.has_dakterras === 'positive' || house.has_garden === 'positive' || house.has_bath === 'positive'" class="font-medium break-all">
                  {{ $trans('AboutThisProperty') }}
                </h4>
                <div v-if="house.house_type" class="flex flex-wrap">
                  <Icon name="check" icon-width="w-[24px] h-[24px] " class="mr-[4px] relative top-[1px]" /> {{ capitalizeFirstLetter($trans(house.house_type)) }}
                </div>
                <div v-if="house.has_balcony && house.has_balcony === 'positive'" class="flex flex-wrap">
                  <Icon name="check" icon-width="w-[24px] h-[24px] " class="mr-[4px] relative top-[1px]" /> {{ $trans(`house.has_balcony`) }}
                </div>
                <div v-if="house.is_sociale_lootwoning && house.is_sociale_lootwoning === 1" class="flex flex-wrap">
                  <Icon name="check" icon-width="w-[24px] h-[24px] " class="mr-[4px] relative top-[1px]" /> {{ $trans(`house.is_sociale_lootwoning`) }}
                </div>
                <div v-if="house.is_on_paid_website && house.is_on_paid_website === 1" class="flex flex-wrap">
                  <Icon name="check" icon-width="w-[24px] h-[24px] " class="mr-[4px] relative top-[1px]" /> {{ $trans(`house.is_on_paid_website`) }}
                </div>
                <div v-if="house.is_for_pets && house.is_for_pets === 'positive'" class="flex flex-wrap">
                  <Icon name="check" icon-width="w-[24px] h-[24px] " class="mr-[4px] relative top-[1px]" /> {{ $trans(`house.is_for_pets`) }}
                </div>
                <div v-if="house.is_for_3_or_more_sharers && house.is_for_3_or_more_sharers === 'positive'" class="flex flex-wrap">
                  <Icon name="check" icon-width="w-[24px] h-[24px] " class="mr-[4px] relative top-[1px]" /> {{ $trans(`house.is_for_3_or_more_sharers`) }}
                </div>
                <div v-if="house.is_for_students && house.is_for_students === 'positive'" class="flex flex-wrap">
                  <Icon name="check" icon-width="w-[24px] h-[24px] " class="mr-[4px] relative top-[1px]" /> {{ $trans(`house.is_for_students`) }}
                </div>
                <div v-if="house.has_dakterras && house.has_dakterras === 'positive'" class="flex flex-wrap">
                  <Icon name="check" icon-width="w-[24px] h-[24px] " class="mr-[4px] relative top-[1px]" /> {{ $trans(`house.has_dakterras`) }}
                </div>
                <div v-if="house.has_garden && house.has_garden === 'positive'" class="flex flex-wrap">
                  <Icon name="check" icon-width="w-[24px] h-[24px] " class="mr-[4px] relative top-[1px]" /> {{ $trans(`house.has_garden`) }}
                </div>
                <div v-if="house.has_bath && house.has_bath === 'positive'" class="flex flex-wrap">
                  <Icon name="check" icon-width="w-[24px] h-[24px] " class="mr-[4px] relative top-[1px]" /> {{ $trans(`house.has_bath`) }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </MyRentbirdCard>

      <div v-if="user.hasActiveSubscription" class="w-full lg:w-1/2 lg:pl-6">
        <MyRentbirdCard :can-expand-on-mobile="false" class="mt-4">
          <template #default>
            <div class="-mt-4">
              <h4 class="font-medium break-all">
                {{ $trans('HousePage.FoundViaSearchProfile') }}
              </h4>

              <SearchProfileCard
                class="mt-[8px]"
                :search-profile="searchProfile"
                :show-edit-button="true"
                :rounded-bottom="true"
              />
            </div>
          </template>
        </MyRentbirdCard>

        <MyRentbirdCard :can-expand-on-mobile="false" class="mt-4">
          <template #default>
            <div class="-mt-4">
              <h4 class="font-medium break-all">
                {{ $trans('HousePage.ShareThisHouse') }}
              </h4>

              <TextInput
                v-model="house.url"
                :disabled="true"
                @click="onClickSharingButton"
              >
                <template #iconRight>
                  <Icon
                    :name="showCopySharingSuccessMessage ? 'check' : 'copy'"
                    :class="{
                      'text-pink-500': true,
                      'cursor-pointer': !showCopySharingSuccessMessage
                    }"
                  />
                </template>
              </TextInput>
            </div>
          </template>
        </MyRentbirdCard>

        <MyRentbirdCard v-if="!feedbackHasBeenSend" :can-expand-on-mobile="false" class="mt-4">
          <template #default>
            <div class="-mt-4">
              <h4 class="font-medium break-all">
                {{ $trans('HousePage.CorrectMatch') }}
              </h4>
              <p class="mb-2">
                {{ $trans('HousePage.CorrectMatch.Body') }}
              </p>
            </div>
            <div>
              <Button
                :outline="correctMatch.feedback === 'positive' ? false : true"
                icon-right="thumbs-up"
                class="mb-2 mr-2"
                @click="correctMatch.feedback = 'positive'; sendFeedback()"
              />
              <Button
                :outline="correctMatch.feedback === 'negative' ? false : true"
                icon-right="thumbs-down"
                class="mb-2"
                @click="correctMatch.feedback = 'negative'"
              />
            </div>
            <div v-if="correctMatch.feedback === 'negative'">
              <TextArea
                :hfull="false"
                class="!h-auto lg:text-lg"
                :rows="4"
                :model-value="correctMatch.comment"
                :placeholder="$trans('HousePage.CorrectMatch.TextArea')"
                @update:model-value="newValue => correctMatch.comment = newValue"
              />
              <Button
                icon-right="send"
                class="mb-2"
                @click="sendFeedback()"
              >
                {{ $trans('HousePage.CorrectMatch.Send') }}
              </Button>
            </div>
          </template>
        </MyRentbirdCard>
      </div>
      <div v-if="!user.hasActiveSubscription" class="w-full lg:w-1/2 lg:pl-6">
        <MyRentbirdCard :can-expand-on-mobile="false" class="mt-4">
          <template #default>
            <PricingCardSimpleNew class="" />
            <Button
              dusk="submit-button"
              class="mb-4"
              :big="true"
              :full-width-on-desktop="true"
              :full-width-on-mobile="true"
              icon-right="unlocked"
              @click="onClickPricingCardPayButton"
            >
              {{ $trans('Upgrade') }}
            </Button>

            <ThreeSmallUspsBlue />
          </template>
        </myrentbirdcard>
      </div>
    </div>

    <DarkPurpleModalOrBottom :is-open="confirmRedirect" :closeable="false">
      <h4 class="text-white text-xl font-medium">
        {{ $trans('HousePage.ForwardModal.Title') }}
      </h4>
      <p class="mt-[6px] text-base">
        {{ $trans('HousePage.ForwardModal.Body') }}
      </p>
      <Checkbox
        :model-value="!showWarningExternalWebsite"
        class="mt-[24px]"
        color="white"
        @update:model-value="(newValue) => (showWarningExternalWebsite = !newValue)"
      >
        {{ $trans('HousePage.ForwardModal.NoModalNextTime') }}
      </Checkbox>
      <Button
        target="_blank"
        :full-width-on-mobile="true"
        :full-width-on-desktop="true"
        icon-right="external"
        class="mt-[32px]"
        @click="confirmRedirect = false; openHouseExternally()"
      >
        <span>{{ $trans('HousePage.ForwardModal.Button') }}</span>
      </Button>
    </darkpurplemodalorbottom>

    <DarkPurpleModalOrBottom :is-open="confirmReacted" :closeable="false">
      <h4 class="text-white text-xl font-medium">
        {{ $trans('HousePage.ReactedModal.Title') }}
      </h4>
      <p class="mt-[6px] text-base">
        {{ $trans('HousePage.ReactedModal.Body') }}
      </p>
      <Button
        :full-width-on-mobile="true"
        :full-width-on-desktop="true"
        icon-right="email-responded"
        class="mt-6"
        @click="react(true)"
      >
        <span>{{ $trans('HousePage.ReactedModal.ButtonReacted') }}</span>
      </Button>
      <Button
        :full-width-on-mobile="true"
        :full-width-on-desktop="true"
        icon-right="arrow-right"
        class="mt-4"
        :outline="true"
        @click="react(false)"
      >
        <span>{{ $trans('HousePage.ReactedModal.ButtonNotReacted') }}</span>
      </Button>
    </darkpurplemodalorbottom>

    <DarkPurpleModalOrBottom :is-open="confirmMatchCorrect" :closeable="false">
      <div v-if="correctMatch.feedback !== 'negative'">
        <h4 class="text-white text-xl font-medium">
          {{ $trans('HousePage.CorrectMatch') }}
        </h4>
        <p class="mt-[6px] text-base mb-6">
          {{ $trans('HousePage.CorrectMatch.Body') }}
        </p>
        <div>
          <Button
            :outline="correctMatch.feedback === 'positive' ? false : true"
            icon-right="thumbs-up"
            class="mb-2 mr-2"
            @click="correctMatch.feedback = 'positive'; sendFeedback()"
          />
          <Button
            :outline="correctMatch.feedback === 'negative' ? false : true"
            icon-right="thumbs-down"
            class="mb-2"
            @click="correctMatch.feedback = 'negative'"
          />
        </div>
      </div>
      <div v-if="correctMatch.feedback === 'negative'">
        <h4 class="text-white text-xl font-medium mb-4">
          {{ $trans('HousePage.CorrectMatch.TextAreaMobileLabel') }}
        </h4>

        <TextArea
          :hfull="false"
          class="!h-auto lg:text-lg text-black"
          :rows="4"
          :model-value="correctMatch.comment"
          :placeholder="$trans('HousePage.CorrectMatch.TextArea')"
          @update:model-value="newValue => correctMatch.comment = newValue"
        />
        <Button
          :full-width-on-mobile="true"
          :full-width-on-desktop="true"
          icon-right="send"
          class="mb-2"
          @click="sendFeedback()"
        >
          {{ $trans('HousePage.CorrectMatch.Send') }}
        </Button>
      </div>
    </darkpurplemodalorbottom>
  </MyRentbirdLayout>
</template>
<style lang="scss">
.MyRentbird-HousePage {
  background: rgb(234, 234, 235);
}
</style>
