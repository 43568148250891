<script setup>

import TrixSegment from '@js/Components/Blog/Segments/TrixSegment.vue'
import TitleSegment from '@js/Components/Blog/Segments/TitleSegment.vue'
import ImageSegment from '@js/Components/Blog/Segments/ImageSegment.vue'
import YoutubeSegment from '@js/Components/Blog/Segments/YoutubeSegment.vue'
import ColumnsSegment from '@js/Components/Blog/Segments/ColumnsSegment.vue'
import ColumnSegment from '@js/Components/Blog/Segments/ColumnSegment.vue'
import * as Sentry from '@sentry/vue'
import ButtonGroupSegment from '@js/Components/Blog/Segments/ButtonGroupSegment.vue'
import ButtonSegment from '@js/Components/Blog/Segments/ButtonSegment.vue'
import FaqSegment from '@js/Components/Blog/Segments/FaqSegment.vue'
import StepsSegment from '@js/Components/Blog/Segments/StepsSegment.vue'

const props = defineProps({
  post: {
    type: Object,
    default: () => ({})
  },
  section: {
    type: Object,
    default: () => ({})
  }
})

const { layout, attributes, key } = props.section

const components = {
  App_Services_Blog_Segments_Title: TitleSegment,
  App_Services_Blog_Segments_SimpleContent: TrixSegment,
  App_Services_Blog_Segments_SimpleImage: ImageSegment,
  App_Services_Blog_Segments_Columns: ColumnsSegment,
  App_Services_Blog_Segments_Column: ColumnSegment,
  App_Services_Blog_Segments_YoutubeEmbed: YoutubeSegment,
  App_Services_Blog_Segments_ButtonGroup: ButtonGroupSegment,
  App_Services_Blog_Segments_Button: ButtonSegment,
  App_Services_Blog_Segments_Faq: FaqSegment,
  App_Services_Blog_Segments_Steps: StepsSegment
}

const component = components[layout]
if (component == null) {
  console.warn(`Blog segment ${layout} not found`)
  Sentry.captureException(new Error(`Blog segment ${layout} not found`))
}
</script>

<template>
  <Component
    :is="components[layout]"
    v-if="component != null"
    :id="props.section.key"
    :key="key"
    :attributes="attributes"
    :post="post"
  />
  <div v-else>
    <p>No such segment {{ layout }} found</p>
    <pre>{{ JSON.stringify(props.section, null, 2) }}</pre>
  </div>
</template>

<style scoped lang="scss">

</style>
