export const rentbirdPlusQualify = (searchProfile) => {
  const csvData =
    'stad (+ omgeving),1br,2br,3br\namsterdam,"€1,800","€2,000","€2,500"\nrotterdam,"€1,600","€1,800","€2,200"\nden haag,"€1,100","€1,500","€1,800"\nutrecht,"€1,500","€1,800","€2,200"\nhaarlem,"€1,600","€1,800","€2,200"\nalmere,"€1,500","€1,700","€2,000"\neindhoven,"€1,500","€1,700","€2,000"\narnhem,"€1,100","€1,500","€1,800"\nnijmegen,"€1,200","€1,500","€1,800"\nrest van nederland,"€1,200","€1,600","€2,000"'

  const rentalData = csvData.split('\n').map((row) => {
    const values = row.match(/(?:"[^"]*")|[^,]+/g).map(cell => cell.replace(/"|€/g, '').trim().toLowerCase())
    return values
  })

  const rentalMap = Object.fromEntries(
    rentalData.map(row => [row[0], row.slice(1).map(value => Number(value.replace(/,/g, '')))])
  )

  const { city, minBedrooms, maxRent } = searchProfile
  let cityName = city.name.toLowerCase()

  if (!rentalMap[cityName]) {
    cityName = 'rest van nederland'
  }

  const bedroomIndex = Math.max(0, minBedrooms - 1) // Adjust index to match 1br -> index 0, 2br -> index 1, etc.
  const requiredRent = rentalMap[cityName][bedroomIndex]

  return maxRent >= requiredRent
}
