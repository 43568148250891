<script setup>
import Button from '@js/Components/Forms/Button/Button.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import ErrorMessage from '@js/Components/Forms/ErrorMessage/ErrorMessage.vue'
import { usePaymentStore } from '@js/App/Stores/PaymentStore'
import { storeToRefs } from 'pinia'

defineProps({
  errors: Object
})

defineEmits(['click:back', 'click:confirm'])

const {
  selectedPrice
} = storeToRefs(usePaymentStore())

console.log('dave', selectedPrice.value)
</script>
<template>
  <div>
    <ErrorMessage v-if="errors.general" class="mb-2">
      {{ errors.general }}
    </ErrorMessage>
    <div class="flex w-full mb-3">
      <Button
        dusk="checkout-back-button"
        class=" lg:mb-0 mr-2"
        :outline="true"
        @click="$emit('click:back')"
      >
        <Icon name="arrow-left" />
      </Button>
      <Button
        dusk="checkout-submit-button"
        class=" lg:mb-0 flex-grow"
        :full-width-on-mobile="true"
        :full-width-on-desktop="true"
        icon-right="check"
        @click="$emit('click:confirm')"
      >
        {{ $trans('Checkout.ConfirmPaymentButtonLabel') }}
      </Button>
    </div>
    <div class="text-gray-500 text-xs pb-3">
      <span>{{ $trans('Checkout.SecurePaymentExtendedPart1') }}
        <span v-if="selectedPrice?.numberOfMonths > 1">
          {{ $trans('PricingCardSimple.Months', { months: selectedPrice?.numberOfMonths}) }}
        </span>
        <span v-else-if="selectedPrice?.numberOfMonths === 1">
          {{ $trans('PricingCardSimple.MonthsSingle', { months: selectedPrice?.numberOfMonths}) }}
        </span>
        <span v-else>
          {{ $trans('PricingCardSimple.Days', { days: selectedPrice?.numberOfDays}) }}
        </span> {{ $trans('Checkout.SecurePaymentExtendedPart2', { price: $formatPrice(selectedPrice?.amount) }) }}</span>
    </div>
  </div>
</template>
