import { storeToRefs } from 'pinia'
import { useSearchProfileStore } from '@js/App/Stores/SearchProfileStore'
import { CREATE_RENTBIRD_PLUS_ACCOUNT_MODAL_ID, toggleModal } from '@js/App/Plugins/Modals'
import { doPostRequest } from '@js/App/Api/Infra/ApiClient'

export const MIN_RENTAL_PRICE_TO_BE_ELIGBLE = 1000

export const isEligbleForRentbirdPlus = () => {
  const { searchProfile, matchesPerWeek } = storeToRefs(useSearchProfileStore())

  const city = searchProfile.value.city?.id
  const minBedrooms = searchProfile.value.minBedrooms

  let minAllowedRentalPrice = MIN_RENTAL_PRICE_TO_BE_ELIGBLE
  let isSpecifiedCity = false
  if (city === 'amsterdam') {
    isSpecifiedCity = true
    if (minBedrooms <= 1) {
      minAllowedRentalPrice = 1500
    } else if (minBedrooms === 2) {
      minAllowedRentalPrice = 1800
    } else {
      minAllowedRentalPrice = 2000
    }
  } else if (city === 'utrecht' || city === 'almere' || city === 'haarlem') {
    isSpecifiedCity = true
    if (minBedrooms <= 1) {
      minAllowedRentalPrice = 1400
    } else if (minBedrooms === 2) {
      minAllowedRentalPrice = 1500
    } else {
      minAllowedRentalPrice = 2000
    }
  } else if (city === 'den-haag' || city === 'rotterdam' || city === 'eindhoven' || city === 'nijmegen') {
    isSpecifiedCity = true
    if (minBedrooms <= 1) {
      minAllowedRentalPrice = 1200
    } else if (minBedrooms === 2) {
      minAllowedRentalPrice = 1400
    } else {
      minAllowedRentalPrice = 1600
    }
  } else if (city === 'arnhem' || city === 'maastricht' || city === 'twente' || city === 'groningen') {
    isSpecifiedCity = true
    if (minBedrooms <= 1) {
      minAllowedRentalPrice = 1200
    } else if (minBedrooms === 2) {
      minAllowedRentalPrice = 1300
    } else {
      minAllowedRentalPrice = 1500
    }
  }

  if (searchProfile.value.maxRent < minAllowedRentalPrice) {
    return false
  }

  if (!isSpecifiedCity) {
    if (matchesPerWeek.value < 10) {
      return false
    }
  }

  return true
}

export const showRentbirdPlusCreateAccountWizard = () => {
  toggleModal(CREATE_RENTBIRD_PLUS_ACCOUNT_MODAL_ID)
}

export const submitStep1 = async () => {
  const { searchProfile } = storeToRefs(useSearchProfileStore())
  const postData = {
    email: searchProfile.value.rentbirdPlusEmail,
    city: searchProfile.value.city?.id,
    search_home_from: searchProfile.value.rentbirdPlusSearchHomeFrom
  }

  if (searchProfile.value.locationSelector === 'neighbourhoods') {
    postData.neighbourhoods = searchProfile.value.neighbourhoods
  } else {
    postData.distance = searchProfile.value.distance
  }

  const response = await doPostRequest('/api/rentbird-plus/step-1', postData)
  return response
}

export const submitStep2 = async () => {
  const { searchProfile } = storeToRefs(useSearchProfileStore())
  const postData = {
    max_rent: searchProfile.value.maxRent,
    min_bedrooms: searchProfile.value.minBedrooms,
    min_surface: searchProfile.value.minSurface,
    going_to_live_with: searchProfile.value.rentbirdPlusGoingToLiveWith,
    is_for_pets: searchProfile.value.isForPets
  }

  const response = await doPostRequest('/api/rentbird-plus/step-2', postData)
  return response
}

export const submitStep3 = async () => {
  const { searchProfile } = storeToRefs(useSearchProfileStore())
  const postData = {
    current_job: searchProfile.value.rentbirdPlusCurrentJob,
    income: searchProfile.value.rentbirdPlusIncome,
    garantsteller: searchProfile.value.rentbirdPlusGarantsteller,
    i_have_more_than_50k: searchProfile.value.rentbirdPlusIHaveMoreThan50K
  }

  if (['with_partner', 'with_partner_and_children'].includes(searchProfile.value.rentbirdPlusGoingToLiveWith)) {
    postData.partner_current_job = searchProfile.value.rentbirdPlusPartnerCurrentJob
    postData.partner_income = searchProfile.value.rentbirdPlusPartnerIncome
  }

  const response = await doPostRequest('/api/rentbird-plus/step-3', postData)
  return response
}

export const submitStep4 = async () => {
  const { searchProfile } = storeToRefs(useSearchProfileStore())
  const postData = {
    email: searchProfile.value.rentbirdPlusEmail,
    city: searchProfile.value.city?.id,
    search_home_from: searchProfile.value.rentbirdPlusSearchHomeFrom,
    max_rent: searchProfile.value.maxRent,
    min_bedrooms: searchProfile.value.minBedrooms,
    min_surface: searchProfile.value.minSurface,
    going_to_live_with: searchProfile.value.rentbirdPlusGoingToLiveWith,
    is_for_pets: searchProfile.value.isForPets,
    current_job: searchProfile.value.rentbirdPlusCurrentJob,
    income: searchProfile.value.rentbirdPlusIncome,
    garantsteller: searchProfile.value.rentbirdPlusGarantsteller,
    i_have_more_than_50k: searchProfile.value.rentbirdPlusIHaveMoreThan50K,
    firstname: searchProfile.value.rentbirdPlusFirstname,
    lastname: searchProfile.value.rentbirdPlusLastname,
    phone: searchProfile.value.rentbirdPlusPhone,
    country_of_residence: searchProfile.value.rentbirdPlusCountry,
    city_of_residence: searchProfile.value.rentbirdPlusCity,
    how_to_reach_me: searchProfile.value.rentbirdPlusHowToReachMe,
    proposition: searchProfile.value.rentbirdPlusProposition,
    price: searchProfile.value.rentbirdPlusPrice
  }

  if (['with_partner', 'with_partner_and_children'].includes(searchProfile.value.rentbirdPlusGoingToLiveWith)) {
    postData.partner_current_job = searchProfile.value.rentbirdPlusPartnerCurrentJob
    postData.partner_income = searchProfile.value.rentbirdPlusPartnerIncome
  }

  if (searchProfile.value.locationSelector === 'neighbourhoods') {
    postData.neighbourhoods = searchProfile.value.neighbourhoods
  } else {
    postData.distance = searchProfile.value.distance
  }

  const response = await doPostRequest('/api/rentbird-plus/new-lead', postData)
  return response
}
