<script setup>
import B2BLayout from '@js/Components/B2B/B2BLayout.vue'
import { usePage, router } from '@inertiajs/vue3'
import { useB2BOrganization } from '@js/App/Api/B2B/B2B'
import MyRentbirdCard from '@js/Components/MyRentbird/MyRentbirdCard/MyRentbirdCard.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import { computed, ref, watch } from 'vue'
import { getQueryParameter } from '@js/App/Utils/ReadQueryParams'
import DataTable from '@js/Components/Tables/DataTable.vue'
import AgentStatusDropdown from '@js/Components/B2B/AgentStatusDropdown.vue'
import { ziggyRoute } from '@js/App/Utils/ZiggyUrls'

const page = usePage()

const agents = computed(() => (page.props.rental_agents))

const { organization } = useB2BOrganization()

const searchQuery = ref(getQueryParameter('search_query', ''))
const sortBy = ref(getQueryParameter('sort_by', 'created_at'))
const sortDirection = ref(getQueryParameter('sort_direction', 'desc'))

const refetchTableWithSearchAndSortParameters = () => {
  let url = `/b2b/${organization.value}/agents`
  url += `?search_query=${encodeURIComponent(searchQuery.value)}`
  url += `&sort_by=${encodeURIComponent(sortBy.value)}`
  url += `&sort_direction=${sortDirection.value}`

  router.visit(url, {
    preserveState: true,
    preserveScroll: true
  })
}

watch(searchQuery, () => {
  const oldValue = searchQuery.value
  setTimeout(() => {
    if (oldValue !== searchQuery.value) { return }

    refetchTableWithSearchAndSortParameters()
  }, 500)
})
watch(sortBy, refetchTableWithSearchAndSortParameters)
watch(sortDirection, refetchTableWithSearchAndSortParameters)

</script>
<template>
  <B2BLayout
    :bread-crumbs="[
      {
        label: 'Housing sites',
        url: ziggyRoute('b2b.agents', { organization: organization})
      },
    ]"
  >
    <template #title>
      <div class="flex items-center">
        <Icon name="house" icon-width="w-[40px] mr-2 relative top-[-2px]" />
        <span>Housing sites</span>
      </div>
    </template>

    <MyRentbirdCard class="mt-6" :can-expand-on-mobile="false">
      <template #title>
        Housing sites
      </template>
      <DataTable
        :search-query="searchQuery"
        :sort-by="sortBy"
        :sort-direction="sortDirection"
        :pagination="agents"
        :headers="[
          {
            label: 'Agent',
            value: 'agent',
            isSortable: false,
          },
          {
            label: 'Url',
            value: 'url',
            isSortable: false,
          },
          {
            label: 'Status',
            value: 'status',
            isSortable: false,
          },
        ]"
        @update:search-query="newSearchQuery => { searchQuery = newSearchQuery }"
        @update:sort-by="newSortBy => { sortBy = newSortBy }"
        @update:sort-direction="newSortAscending => { sortDirection = newSortAscending }"
      >
        <template
          v-for="item of agents.data"
          :key="item?.random_id"
        >
          <tr class="row">
            <td>
              <div>
                {{ item.name }}
              </div>
            </td>
            <td>
              {{ item.url }}
            </td>
            <td>
              <AgentStatusDropdown
                :agent-id="item.id"
                :agent-status="item.blocked_status"
              />
            </td>
          </tr>
        </template>
      </DataTable>
    </MyRentbirdCard>
  </B2BLayout>
</template>
<style lang="scss">
.B2B-HousesPage {
  background: rgb(234,234,235);
}
</style>
