<script setup>
import { router, usePage } from '@inertiajs/vue3'
import HouseCardMatchHistory from '@js/Components/HouseCards/HouseCardMatchHistory/HouseCardMatchHistory.vue'
import Pagination from '@js/Components/Pagination/Pagination.vue'
import InfoMessageBox from '@js/Components/InfoMessageBox/InfoMessageBox.vue'
import Image from '@js/Components/Image/Image.vue'
import ScaredFace from '@images/icons/scared-face.png'
import MyRentbirdLayout from '@js/Components/MyRentbird/MyRentbirdLayout/MyRentbirdLayout.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import TextInput from '@js/Components/Forms/TextInput/TextInput.vue'
import SortButton from '@js/Components/Forms/Button/ButtonSort.vue'
import { useUser } from '@js/App/Api/User/User'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import { ref, watch, computed } from 'vue'
import { getQueryParameter } from '@js/App/Utils/ReadQueryParams'
import { useIsMobile } from '@js/App/Utils/BrowserDetection'

const { user } = useUser()
const page = usePage()
const houses = computed(() => page.props.houses?.data || [])
const { isMobile } = useIsMobile()
const baseUrl = getLocalizedUrl('my-rentbird.match-history')
const sortUrl = ref('')
const sortBy = ref(getQueryParameter('sort_by') ?? 'created_at')
const ascBool = ref((getQueryParameter('asc') ?? false) === 'true')
const searchTerm = ref(getQueryParameter('q') ?? '')

const getSortUrl = (sort, asc, q) => {
  const separationChar = baseUrl?.includes('?') ? '&' : '?'
  sortUrl.value = baseUrl + separationChar + 'sort_by=' + sort + '&asc=' + (asc !== true) + (q ? '&q=' + q : '')
  return sortUrl.value
}

const sortMatches = (sort, asc, q) => {
  const url = sortBy.value === sort ? getSortUrl(sort, asc, q) : getSortUrl(sort, !asc, q)
  router.visit(url, { preserveScroll: true })
}

let timer
const debounceGetHousesWithSearchTerm = () => {
  timer = setTimeout(() => {
    router.visit(getSortUrl(sortBy.value, !ascBool.value, searchTerm.value), { preserveScroll: true })
  }, 750)
}

watch(searchTerm, () => {
  clearTimeout(timer)
  debounceGetHousesWithSearchTerm()
})
</script>

<template>
  <MyRentbirdLayout>
    <div class="bg-white rounded px-4 py-4 lg:px-8 lg:py-8 mt-4">
      <div class="block lg:flex justify-between text-purple-900 transition-colors">
        <div>
          <h4 class="text-xl lg:text-3xl font-medium">
            {{ $trans('MatchHistoryPage.Title') }}
          </h4>
          <p class="text-sm lg:text-lg text-purple-900 mb-2 lg:mb-4" v-html="$trans('MatchHistoryPage.Text')" />
        </div>
        <div>
          <TextInput
            v-model="searchTerm"
            :black-border="true"
            :placeholder="
              isMobile ? $trans('MyRentbird.MatchHistorySearchBarMobile') : $trans('MyRentbird.MatchHistorySearchBar')
            "
            icon="search"
          >
            <template #icon>
              <Icon name="search" />
            </template>
          </TextInput>
          <div class="flex flex-row pt-3 lg:pt-4 items-center content-end">
            <div class="hidden lg:block pr-3">
              {{ $trans('MatchHistoryPage.SortBy') }}
            </div>
            <SortButton
              class="pr-1"
              :icon-right="ascBool ? 'arrow-up-simple' : 'arrow-down-simple'"
              :filter-active="sortBy === 'created_at'"
              @click="sortMatches('created_at', ascBool, searchTerm)"
            >
              {{ $trans('MatchHistoryPage.SortByLatestLabel') }}
            </SortButton>
            <SortButton
              class="pr-1"
              :icon-right="ascBool ? 'arrow-up-simple' : 'arrow-down-simple'"
              :filter-active="sortBy === 'rent'"
              @click="sortMatches('rent', ascBool, searchTerm)"
            >
              {{ $trans('MatchHistoryPage.SortByRentLabel') }}
            </SortButton>
            <SortButton
              :filter-actve="false"
              :icon-right="ascBool ? 'arrow-up-simple' : 'arrow-down-simple'"
              :filter-active="sortBy === 'area'"
              @click="sortMatches('area', ascBool, searchTerm)"
            >
              {{ $trans('MatchHistoryPage.SortBySurfaceLabel') }}
            </SortButton>
          </div>
        </div>
      </div>

      <div class="pt-3 lg:pt-4">
        <InfoMessageBox v-if="!user.hasActiveSubscription" color="pink">
          <div class="flex items-start">
            <Image :src="ScaredFace" class="relative top-[3px] mr-[6px]" />
            <div
              class="text-base text-purple-900"
              v-html="$trans('MatchHistoryPage.FreeAccountMessage', { url: $getLocalizedUrl('upgrade') })"
            />
          </div>
        </InfoMessageBox>

        <div class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-3">
          <HouseCardMatchHistory
            v-for="item of houses"
            :key="item.house.random_id"
            :house-id="item.house.id"
            class="mb-[12px] lg:mb-[24px]"
            :name="item.house.name"
            :image="item.house.image_url"
            :url="item.house.click_track_url"
            :city="item.house.city"
            :bedrooms="item.house.bedrooms"
            :area="item.house.area"
            :rent="item.house.rent"
            :found-at-formatted="item.house.created_at_formatted_diff"
            :rental-agent="item.house.rental_agent_name"
            :show-viewed-label="true"
            :has-viewed="item.house.has_viewed"
            :is-historic="item.house.is_historic"
            :is-bookmarked="item.house.is_bookmarked"
            :is-reacted="item.house.is_reacted"
          />

          <InfoMessageBox v-if="houses?.length <= 0" color="blue">
            {{ $trans('MatchHistoryPage.NoMatchesYetMessage') }}
          </InfoMessageBox>
        </div>
        <Pagination
          v-if="houses.length > 9 || page.props.houses?.last_page > 2"
          class="mt-[32px]"
          :base-url="baseUrl + '&sort_by=' + sortBy + '&asc=' + ascBool + '&q=' + searchTerm"
          :current-page="page.props.houses?.current_page"
          :last-page="page.props.houses?.last_page"
        />
      </div>
    </div>
  </MyRentbirdLayout>
</template>
<style lang="scss">
.MyRentbird-MatchHistoryPage {
  background: rgb(234, 234, 235);
}
</style>
