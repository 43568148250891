<script setup>
const props = defineProps({
  attributes: {
    type: Object,
    default: () => ({})
  },
  post: {
    type: Object,
    default: () => ({})
  }
})
// roughly calc reading time based on all text in components
const minutedReadingTime = Math.ceil(props.post.content.map(content => Object.keys(content.attributes).map(key => content.attributes[key]?.length || 0).reduce((acc, el) => acc + el)).reduce((acc, el) => acc + el) / 1200)

</script>

<template>
  <div>
    <div class="flex flex-col lg:flex-row min-h-96 gap-4 lg:gap-40 justify-between">
      <div class="flex flex-col lg:flex-col-reverse lg:justify-center gap-4">
        <div class="">
          <h1 class="text-3xl text-black font-medium lg:text-4xl lg:mt-[10px]">
            {{ post.meta_title }}
          </h1>
          <div class="lg:mt-6">
            {{ post.formatted_date }}
          </div>
        </div>
        <div class="flex gap-2 mt-4 lg:mt-0 flex-wrap">
          <div class="flex flex-row gap-2">
            <span v-for="tag in post.tags" :key="tag.id" class="bg-purple-800 text-white p-1 px-3 rounded-full text-sm inline-block">
              {{ tag.name }}
            </span>
          </div>
          <div>{{ $trans('OnboardingTipsPage.MinutesReadingTime', { minutes: minutedReadingTime }) }}</div>
        </div>
      </div>
      <div>
        <img class="rounded-md h-full object-cover w-full" :src="props.post.image_url" :alt="props.post.meta_image_alt">
      </div>
    </div>
    <p class="mt-10 blogContent" v-html="props.attributes.content" />
  </div>
</template>

<!-- Also used for TrixSegment.vue -->
<style>
.blogContent a {
  color: #00A2CE;
}

.blogContent a:hover {
  text-decoration: underline;
  color: #00A2CE;
}
</style>
