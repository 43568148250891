<script setup>
import Icon from '@js/Components/Icon/Icon.vue'
import { watch, ref } from 'vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  }
})
const emit = defineEmits(['update:modelValue'])
const open = ref(props.modelValue)

watch(() => props.modelValue, (newValue) => {
  open.value = newValue
})

watch(open, (newOpen) => {
  emit('update:modelValue', newOpen)
})

</script>

<template>
  <div v-if="open" class="fixed w-screen h-screen top-0 left-0 lg:pointer-events-none z-50">
    <div
      class="flex flex-col w-full bg-white fixed bottom-0 px-6 pb-10 rounded-t-lg lg:w-[30rem] lg:right-5 z-30 pointer-events-auto"
    >
      <div class="flex justify-between bg-red-500 cursor-pointer pt-6" @click="open = false">
        <div>
          <h4 class="font-medium text-base mb-2">
            {{ $trans('RentalChanceModal.title') }}
          </h4>
          <p class="text-base">
            {{ $trans('RentalChanceModal.body') }}
          </p>

          <div class="my-6 text-[#00B67A]">
            <div class="flex items-center align-middle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path d="M4.80078 4.80005L4.80078 19.2" stroke="#00B67A" stroke-width="3.6" stroke-linecap="round" />
                <path d="M12 4.80005L12 19.2" stroke="#00B67A" stroke-width="3.6" stroke-linecap="round" />
                <path d="M19.1992 4.80005L19.1992 19.2" stroke="#00B67A" stroke-width="3.6" stroke-linecap="round" />
              </svg>
              <p class="ml-2 font-medium">
                {{ $trans('RentalChanceModal.super.title') }}
              </p>
            </div>
            <p class="mt-2">
              {{ $trans('RentalChanceModal.super.body') }}
            </p>
          </div>

          <div class="my-6 text-[#AFC429]">
            <div class="flex items-center align-middle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path d="M4.80078 4.80005L4.80078 19.2" stroke="#AFC429" stroke-width="3.6" stroke-linecap="round" />
                <path d="M12 4.80005L12 19.2" stroke="#AFC429" stroke-width="3.6" stroke-linecap="round" />
                <path opacity="0.3" d="M19.1992 4.80005L19.1992 19.2" stroke="#AFC429" stroke-width="3.6" stroke-linecap="round" />
              </svg>
              <p class="ml-2 font-medium">
                {{ $trans('RentalChanceModal.high.title') }}
              </p>
            </div>
            <p class="mt-2">
              {{ $trans('RentalChanceModal.high.body') }}
            </p>
          </div>

          <div class="my-6 text-[#E79F35]">
            <div class="flex items-center align-middle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path d="M4.80078 4.80005L4.80078 19.2" stroke="#E79F35" stroke-width="3.6" stroke-linecap="round" />
                <path opacity="0.3" d="M12 4.80005L12 19.2" stroke="#E79F35" stroke-width="3.6" stroke-linecap="round" />
                <path opacity="0.3" d="M19.1992 4.80005L19.1992 19.2" stroke="#E79F35" stroke-width="3.6" stroke-linecap="round" />
              </svg>
              <p class="ml-2 font-medium">
                {{ $trans('RentalChanceModal.medium.title') }}
              </p>
            </div>
            <p class="mt-2">
              {{ $trans('RentalChanceModal.medium.body') }}
            </p>
          </div>

          <div class="my-6 text-[#EF4444]">
            <div class="flex items-center align-middle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path opacity="0.2" d="M4.80078 4.80005L4.80078 19.2" stroke="#EF4444" stroke-width="3.6" stroke-linecap="round" />
                <path opacity="0.2" d="M12 4.80005L12 19.2" stroke="#EF4444" stroke-width="3.6" stroke-linecap="round" />
                <path opacity="0.2" d="M19.1992 4.80005L19.1992 19.2" stroke="#EF4444" stroke-width="3.6" stroke-linecap="round" />
              </svg>
              <p class="ml-2 font-medium">
                {{ $trans('RentalChanceModal.low.title') }}
              </p>
            </div>
            <p class="mt-2">
              {{ $trans('RentalChanceModal.low.body') }}
            </p>
          </div>
        </div>
        <Icon name="cross" class="" />
      </div>
    </div>
    <div class="bg-black z-20 opacity-50 w-full h-full top-0 left-0 lg:hidden" @click="open = false" />
  </div>
</template>
