<script setup>
import SearchProfileCardLabel from '@js/Components/SearchProfileCard/SearchProfileCardLabel.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import { capitalizeFirstLetter } from '@js/App/Utils/StringFormatting'
import { getCurrency } from '@js/App/Utils/CountryAndLocale'
import MatchPageRentalChanceBar from '@js/Components/HouseCards/MatchPageRentalChanceBar/MatchPageRentalChanceBar.vue'

import { bookmarkHouse, unbookmarkHouse, reactToHouse, unreactToHouse } from '@js/App/Api/User/Houses'

const props = defineProps({
  houseId: Number,
  image: String,
  name: String,
  rentalAgent: String,
  foundAtFormatted: String,
  city: String,
  bedrooms: Number,
  area: Number,
  rent: Number,
  showViewedLabel: Boolean,
  hasViewed: Boolean,
  isHistoric: Boolean,
  url: String,
  isBookmarked: Boolean,
  isReacted: Boolean,
  rentalChance: String
})

const bookmark = async (e) => {
  e.preventDefault()

  props.isBookmarked ? await unbookmarkHouse(props.houseId) : await bookmarkHouse(props.houseId)
}

const react = async (e) => {
  e.preventDefault()

  props.isReacted ? await unreactToHouse(props.houseId) : await reactToHouse(props.houseId)
}

</script>

<template>
  <div>
    <MatchPageRentalChanceBar
      :rental-chance="rentalChance"
    />
    <div class="relative">
      <img :src="image" alt="house image" class="rounded-b-lg w-full h-[151px] lg:h-[235px] object-cover">
      <SearchProfileCardLabel class="m-2 absolute bottom-0 left-0 cursor-pointer" @click="react">
        <div class="flex items-center">
          <span v-if="isReacted" class="text-pink-500 font-medium text-sm mr-2">{{ $trans('RemoveReaction') }}</span><span v-else class="text-pink-500 font-medium text-sm mr-2">{{ $trans('AddReaction') }}</span> <Icon :name="isReacted ? 'folder-minus' : 'folder-plus'" icon-width="w-[24px] h-[24px]" class="text-pink-500" />
        </div>
      </SearchProfileCardLabel>
      <SearchProfileCardLabel class="m-2 absolute bottom-0 right-0 cursor-pointer" @click="bookmark">
        <div class="flex">
          <Icon :name="isBookmarked ? 'heart' : 'heart-light'" icon-width="w-[24px] h-[24px]" class="text-pink-500" />
        </div>
      </SearchProfileCardLabel>
    </div>

    <div
      class="transition-colors my-6 lg:my-4"
    >
      <div class="flex flex-wrap">
        <SearchProfileCardLabel class="mr-[4px] mt-[4px] rounded-lg" color="blue">
          <div class="flex">
            <Icon name="location" icon-width="w-[20px] h-[20px] " class="mr-[4px] relative top-[1px]" />
            <span class="text-sm text-purple-900">{{ capitalizeFirstLetter(city) }}</span>
          </div>
        </SearchProfileCardLabel>

        <SearchProfileCardLabel class="mr-[4px] mt-[4px] rounded-lg" color="blue">
          <div class="flex">
            <Icon name="bed-alternative" icon-width="w-[20px] h-[20px] " class="mr-[4px] relative top-[1px]" />
            <span class="text-sm text-purple-900">{{ bedrooms }}</span>
          </div>
        </SearchProfileCardLabel>

        <SearchProfileCardLabel class="mr-[4px] mt-[4px] rounded-lg" color="blue">
          <div class="flex">
            <Icon name="ruler" icon-width="w-[20px] h-[20px] " class="mr-[4px] relative top-[1px]" />
            <span class="text-sm text-purple-900">{{ area }}m<sup>2</sup></span>
          </div>
        </SearchProfileCardLabel>

        <SearchProfileCardLabel class="mr-[4px] mt-[4px] rounded-lg" color="blue">
          <div class="flex">
            <Icon name="tag" icon-width="w-[20px] h-[20px] " class="mr-[4px] relative top-[2px]" />
            <span class="text-sm text-purple-900">{{ getCurrency() }}{{ rent }}</span>
          </div>
        </SearchProfileCardLabel>
      </div>
    </div>
  </div>
</template>
