<script setup>

import { Link } from '@inertiajs/vue3'
import Icon from '@js/Components/Icon/Icon.vue'
import { showCreateSearchProfileWizard } from '@js/App/SearchProfile'

const props = defineProps({
  attributes: {
    type: Object,
    default: () => ({})
  },
  post: {
    type: Object,

    default: () => ({})
  }
})
const type = props.attributes.type

const isSpecial = props.attributes.href?.startsWith('@')

function specialAction (e) {
  e.preventDefault()
  switch (props.attributes.href) {
    case '@showWizard':
      showCreateSearchProfileWizard()
      break
    default:
      console.warn(`no such action: ${props.attributes.href}`)
  }
}
</script>

<template>
  <button
    v-if="isSpecial"
    class="p-3 rounded-md flex items-center justify-center transition-colors text-base font-medium border-pink-500"
    :class="{
      [`text-white bg-pink-500 hover:brightness-90 transition-all border`]: type === 'primary',
      [`text-pink-500 hover:text-white hover:bg-pink-500 border`]: type === 'secondary',
      [`text-gray-800 hover:text-gray-600`]: type === 'tertiary',
    }"
    @click="specialAction"
  >
    {{ attributes.text }}
    <Icon name="arrow-right" class="relative top-[0px] pl-2" />
  </button>
  <Link
    v-else
    :href="attributes.href"
    class="p-3 rounded-md flex items-center justify-center transition-colors text-base font-medium border-pink-500"
    :class="{
      [`text-white bg-pink-500 hover:brightness-90 transition-all border`]: type === 'primary',
      [`text-pink-500 hover:text-white hover:bg-pink-500 border`]: type === 'secondary',
      [`text-gray-800 hover:text-gray-600`]: type === 'tertiary',
    }"
  >
    {{ attributes.text }}
    <Icon name="arrow-right" class="relative top-[0px] pl-2" />
  </Link>
</template>
