<script setup>
import PricingCardRentbirdButton from '@js/Components/PricingCard/PricingCardRentbird/PricingCardRentbirdButton.vue'
import { usePaymentStore } from '@js/App/Stores/PaymentStore'
import { storeToRefs } from 'pinia'
import { onMounted } from 'vue'
import { trackSubscriptionSelection } from '@js/App/Tracking/Tracking'
import { useUser } from '@js/App/Api/User/User'
import { formatPrice } from '@js/App/Plugins/Formatting'

const paymentStore = usePaymentStore()
const { selectedPrice, availablePrices } = storeToRefs(usePaymentStore())

const setSelectedPrice = (price) => {
  paymentStore.removeCoupon()
  selectedPrice.value = price

  trackSubscriptionSelection(selectedPrice.value.plan)
}

onMounted(() => {
  paymentStore.removeCoupon()
})
const { user, userActivePayment } = useUser()
</script>
<template>
  <div>
    <h2 class="text-purple-900 font-medium text-lg lg:text-2xl mb-2 lg:mb-4">
      {{ $trans('PricingCardSimple.Title') }}
    </h2>
    <PricingCardRentbirdButton
      v-for="(price,index) of availablePrices.map(item => item)"
      :key="price.plan"
      :index="index"
      :is-active="selectedPrice?.plan === price.plan"
      :price="price"
      theme="light"
      @click="setSelectedPrice(price)"
    >
      <template #title>
        <div>
          <div class="flex flex-row items-center">
            <span class="text-base lg:text-lg text-purple-900">{{ $trans('PricingCardSimple.Discount', { discount: price.discount || 0 }) }}</span>
            <span v-if="index === 0 && (!user.isLoggedIn || userActivePayment === null)" class="text-blue-base text-xs border border-blue-base px-4 py-0.5 lg:px-[6px] lg:ml-[8px] rounded-lg hidden xl:flex">
              {{ $trans('PricingCardSimpleButton.Label') }}
            </span>
            <span v-if="user.isLoggedIn && userActivePayment && price.plan === userActivePayment.plan" class="text-blue-base text-xs border border-blue-base px-4 py-0.5 lg:px-[6px] lg:ml-[8px] rounded-lg hidden xl:flex">
              {{ $trans('PricingCardSimpleButton.LabelUser') }}
            </span>
          </div>

          <div class="text-gray-800 text-xs hidden xl:flex font-normal">
            {{ $trans('PricingCardAlternative.DisclaimerText') }}
          </div>
        </div>
      </template>

      <template #price>
        <div
          class="text-sm lg:text-base lg:font-medium"
          :class="{
            'text-success': index !== 2,
            'text-danger-soft': index === 2,
          }"
        >
          {{ formatPrice(availablePrices[index].amountPerMonth) }} {{ $trans('PricingCardAlternative.PerMonth') }}
        </div>
        <span class="text-xs text-gray-800">   {{ $trans(price.numberOfMonths > 1 ? 'PricingCardSimple.Months' : 'PricingCardSimple.MonthsSingle', {
          months: price.numberOfMonths
        }) }}</span>
      </template>
    </PricingCardRentbirdButton>
  </div>
</template>
