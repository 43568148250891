<script setup>
import { Link } from '@inertiajs/vue3'
import Icon from '@js/Components/Icon/Icon.vue'
import { computed } from 'vue'

const { pagination } = defineProps({
  pagination: {
    type: Object,
    default: () => ({})
  }
})

const linksToShow = computed(() => {
  const currentPage = pagination.current_page

  const onEachSide = 1

  const links = []
  for (let i = currentPage - onEachSide; i <= currentPage + onEachSide; i += 1) {
    if (i < 1) { continue }
    if (i > currentPage + onEachSide) { continue }
    if (i > pagination.last_page) { continue }

    links.push({
      url: pagination.links[i].url,
      page: i,
      active: i === pagination.current_page
    })
  }

  if (links[0].page > 1) {
    links.unshift({
      page: '...',
      active: false
    })
    links.unshift({
      page: 1,
      active: false
    })
  }

  if (links[links.length - 1].page < pagination.last_page) {
    links.push({
      page: '...',
      active: false
    })
    links.push({
      page: pagination.last_page,
      url: pagination.last_page_url,
      active: false
    })
  }

  return links
})
</script>

<template>
  <div>
    <div class="flex justify-between">
      <Link
        v-if="pagination.prev_page_url !== null"
        :href="pagination.prev_page_url"
        class="h-14 w-14 flex justify-center items-center border rounded-md border-pink-500 text-pink-500"
      >
        <Icon name="arrow-outline-left" />
      </Link>
      <div v-else class="h-14 w-14 flex justify-center items-center border rounded-md border-pink-500 text-pink-500 opacity-30">
        <Icon name="arrow-outline-left" />
      </div>
      <div class="flex gap-4">
        <div
          v-for="(link) in linksToShow"
          :key="link.url"
        >
          <Link
            v-if="link.page !== '...'"
            :href="link.url"
            class="h-14 w-14  flex justify-center items-center border rounded-full"
            :class="{
              'text-gray-400 border-gray-400': !link.active,
              'bg-pink-500 text-white border-pink-500': link.active,
            }"
          >
            <span class="self-center">{{ link.page }}</span>
          </Link>
          <div v-else class="h-14 w-14 flex justify-center items-center rounded-full">
            ...
          </div>
        </div>
      </div>
      <Link
        v-if="pagination.next_page_url !== null"
        :href="pagination.next_page_url"
        class="h-14 w-14 flex justify-center items-center border rounded-md border-pink-500 text-pink-500"
      >
        <Icon name="arrow-outline-right" />
      </Link>
      <div v-else class="h-14 w-14 flex justify-center items-center border rounded-md border-pink-500 text-pink-500 opacity-30">
        <Icon name="arrow-outline-right" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
