<script setup>
import { Head, Link, usePage } from '@inertiajs/vue3'
import RenderPageSegment from '@js/Components/Blog/RenderPageSegment.vue'
import CallToActionSection from '@js/Components/Marketing/CallToActionSection/CallToActionSection.vue'
import Footer from '@js/Components/Marketing/Footer/Footer.vue'
import BlogFooterAuthor from '@js/Components/Blog/BlogFooterAuthor.vue'
import MyRentbirdNavbar from '@js/Components/MyRentbird/MyRentbirdNavbar/MyRentbirdNavbar.vue'
import ListingHeroDivider from '@js/Components/ListingPages/ListingHeroDivider.vue'
import RelatedTips from '@js/Components/Blog/RelatedTips.vue'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import Icon from '@js/Components/Icon/Icon.vue'

const page = usePage()

const { post } = defineProps({
  post: {
    type: Object,
    default: () => ({})
  },
  relatedPosts: {
    type: Array,
    default: () => ([])
  }
})
const pageTitle = `${post.meta_title} - Rentbird`
</script>

<template>
  <Head>
    <title>{{ pageTitle }}</title>
    <meta name="description" :content="post.meta_description">

    <!-- Facebook Meta Tags -->
    <meta property="og:url" :content="page.props.fullUrl">
    <meta property="og:type" content="website">
    <meta property="og:title" :content="pageTitle">
    <meta property="og:description" :content="post.meta_description">
    <meta property="og:image" :content="post.image_url">
    <meta property="og:image:alt" :content="post.meta_image_alt">

    <!-- Twitter Meta Tags -->
    <meta name="twitter:card" content="summary_large_image">
    <meta property="twitter:url" :content="page.props.fullUrl">
    <meta name="twitter:title" :content="pageTitle">
    <meta name="twitter:description" :content="post.meta_description">
    <meta name="twitter:image" :content="post.image_url">
    <meta name="twitter:image:alt" :content="post.meta_image_alt">
  </Head>
  <div class="flex flex-col bg-gray-50 min-h-screen">
    <MyRentbirdNavbar />
    <div class="bg-purple-700 h-48" />
    <ListingHeroDivider />

    <div>
      <div class="container mx-auto px-4">
        <div class="-mt-48 flex flex-col gap-8 mb-20">
          <Link :href="getLocalizedUrl('blog-posts')" class="flex gap-1 hover:text-blue-base items-center text-white -mb-4 lg:ml-8">
            <Icon name="arrow-left" />{{ $trans("Blog.BackToAllTips") }}
          </Link>
          <div class="bg-white px-4 pt-6 pb-10 2xl:pb-20 2xl:pt-10 rounded-md">
            <div class="max-w-screen-xl mx-auto">
              <div class="flex flex-col gap-10">
                <div v-for="section in post.content" :key="section.key">
                  <RenderPageSegment :section="section" :post="post" />
                </div>
                <BlogFooterAuthor :author="post.author" />

                <div class="bg-gray-100 w-full h-[1px] mb-6" />
                <RelatedTips :related-posts="relatedPosts" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <CallToActionSection section-divider-color="#EAEAEB" />
      <Footer />
    </div>
  </div>
</template>

<style lang="scss">
.Blog-ViewPost {
  background: rgb(234, 234, 235);
}
</style>
