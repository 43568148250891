<script setup>
import RentbirdLogo from '@js/Components/Logos/RentbirdLogo/RentbirdLogo.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import TrustpilotSmall from '@js/Components/Trustpilot/TrustpilotSmall.vue'
import FooterLinkMenu from '@js/Components/Marketing/Footer/FooterLinkMenu.vue'
import { getLocale } from '@js/App/Utils/CountryAndLocale'
import LoadingSpinner from '@js/Components/Forms/LoadingSpinner/LoadingSpinner.vue'
import Container from '@js/Components/Container/Container.vue'
import { useIsMobile } from '@js/App/Utils/BrowserDetection'
import { ref } from 'vue'
import { isAndroidAppEnabled, isIphoneAppEnabled } from '@js/App/FeatureFlags'
import ButtonAppDownload from '@js/Components/Forms/Button/ButtonAppDownload.vue'

const currentYear = new Date().getFullYear()

const locale = getLocale()
const instagramUrl = `https://www.instagram.com/rentbird/?hl=${locale}`
const facebookUrl = 'https://www.facebook.com/rentbird/'

const partnerAffiliateLink =
  locale === 'nl' ? 'https://lp.rentbird.nl/partners-en-affiliates' : 'https://lp.rentbird.nl/partners-and-affiliates'
const creatorInfluencerLink =
  locale === 'nl' ? 'https://lp.rentbird.nl/creators-en-influencers' : 'https://lp.rentbird.nl/creators-and-influencers'

const { isMobile } = useIsMobile()
const showAllLinks = ref(false)
const handeClick = () => {
  showAllLinks.value = !showAllLinks.value
}
</script>
<template>
  <footer>
    <div class="bg-white">
      <Container>
        <div class="pt-12 lg:pt-16 lg:flex lg:w-full lg:justify-between">
          <RentbirdLogo :href="false" class="mb-9" />

          <FooterLinkMenu
            class="mb-6"
            :show-all-links="true"
            :is-open-by-default="true"
            :links="[
              {
                href: $getLocalizedUrl('how-it-works'),
                text: $trans('Footer.HowItWorks')
              },
              {
                href: $getLocalizedUrl('rentbird-plus'),
                text: 'Rentbird Plus'
              },
              {
                href: $getLocalizedUrl('pricing'),
                text: $trans('Footer.Pricing')
              },
              {
                href: $getLocalizedUrl('reviews'),
                text: $trans('Footer.Reviews')
              },
              {
                href: $getLocalizedUrl('my-rentbird.home'),
                text: $trans('Footer.MyRentbird'),
                tag: 'a'
              },
              {
                href: $getLocalizedUrl('contact'),
                text: $trans('Footer.Support')
              },
              {
                href: $getLocalizedUrl('faq'),
                text: $trans('Footer.Faq')
              },
              {
                href: $getLocalizedUrl('rental-sentiment'),
                text: 'Huursentiment 2024'
              },
              {
                href: $getLocalizedUrl('all-houses-categories'),
                text: $trans('Footer.ViewApartments'),
              },
              {
                href: $getLocalizedUrl('blog-posts'),
                text: $trans('Footer.Blog'),
              },
              {
                href: $getLocalizedUrl('about-us'),
                text: $trans('Footer.AboutUs')
              },
              {
                target: '_blank',
                href: partnerAffiliateLink,
                text: $trans('Footer.PartnersAndAffiliates'),
                tag: 'a'
              },
              {
                target: '_blank',
                href: creatorInfluencerLink,
                text: $trans('Footer.CreatorsAndInfluencers'),
                tag: 'a'
              }
            ]"
          >
            <template #title>
              {{ $trans('Footer.AboutRentbird') }}
            </template>
          </FooterLinkMenu>

          <FooterLinkMenu
            v-if="isMobile"
            class="mb-6"
            :show-all-links="showAllLinks"
            :is-open-by-default="false"
            :links="[
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'amsterdam' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Amsterdam' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'rotterdam' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Rotterdam' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'den-haag' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Den Haag' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'utrecht' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Utrecht' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'haarlem' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Haarlem' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'eindhoven' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Eindhoven' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'tilburg' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Tilburg' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'breda' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Breda' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'nijmegen' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Nijmegen' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'groningen' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Groningen' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'almere' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Almere' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'leiden' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Leiden' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'zwolle' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Zwolle' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'amersfoort' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Amersfoort' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'arnhem' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Arnhem' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'enschede' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Enschede' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'delft' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Delft' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'alkmaar' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Alkmaar' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'tilburg' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Tilburg' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'breda' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Breda' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'nijmegen' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Nijmegen' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'groningen' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Groningen' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'almere' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Almere' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'leiden' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Leiden' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'dordrecht' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Dordrecht' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'leeuwarden' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Leeuwarden' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'zaandam' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Zaandam' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'zoetermeer' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Zoetermeer' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'wageningen' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Wageningen' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'den-bosch' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Den-Bosch' })
              }
            ]"
          >
            <template #showallcities>
              <div @click="handeClick">
                {{ showAllLinks ? $trans('Footer.ShowLessCities') : $trans('Footer.ShowAllCities') }}
              </div>
            </template>
            <template #title>
              {{ $trans('Footer.RentalHomesPerCity') }}
            </template>
          </FooterLinkMenu>

          <FooterLinkMenu
            v-if="!isMobile"
            :show-all-links="showAllLinks"
            class="mb-6"
            :is-open-by-default="false"
            :links="[
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'amsterdam' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Amsterdam' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'rotterdam' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Rotterdam' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'den-haag' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Den Haag' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'utrecht' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Utrecht' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'haarlem' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Haarlem' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'eindhoven' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Eindhoven' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'zwolle' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Zwolle' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'amersfoort' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Amersfoort' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'arnhem' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Arnhem' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'enschede' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Enschede' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'delft' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Delft' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'alkmaar' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Alkmaar' })
              }
            ]"
          >
            <template #title>
              {{ $trans('Footer.RentalHomesPerCity') }}
            </template>
          </FooterLinkMenu>

          <FooterLinkMenu
            v-if="!isMobile"
            :show-all-links="showAllLinks"
            class="mb-10"
            :is-open-by-default="false"
            :links="[
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'tilburg' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Tilburg' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'breda' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Breda' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'nijmegen' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Nijmegen' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'groningen' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Groningen' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'almere' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Almere' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'leiden' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Leiden' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'dordrecht' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Dordrecht' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'leeuwarden' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Leeuwarden' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'zaandam' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Zaandam' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'zoetermeer' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Zoetermeer' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: 'wageningen' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Wageningen' })
              },
              {
                href: $getLocalizedUrl('all-houses-in-city', { city: '\'s-hertogenbosch' }),
                text: $trans('Footer.RentalHomesIn', { city: 'Den-Bosch' })
              }
            ]"
          >
            <template #showallcities>
              <div @click="handeClick">
                {{ showAllLinks ? $trans('Footer.ShowLessCities') : $trans('Footer.ShowAllCities') }}
              </div>
            </template>
          </FooterLinkMenu>

          <div>
            <TrustpilotSmall :black="true" layout="vertical" class="hidden xl:block" />
            <div class="flex space-y-2 flex-col mt-4">
              <ButtonAppDownload
                v-if="isIphoneAppEnabled()"
                :is-android="false"
                class="w-[186px]"
                href="https://apps.apple.com/us/app/rentbird-find-rental-houses/id6468817766"
                :is-black="true"
              />
              <ButtonAppDownload
                v-if="isAndroidAppEnabled()"
                :is-android="true"
                class="w-48"
                href="https://play.google.com/store/apps/details?id=io.rentbird"
                :is-black="true"
              />
            </div>
          </div>
        </div>
      </Container>
      <Container>
        <div class="pb-12">
          <div class="w-full border-t border-gray-400 mb-8" />

          <div class="block lg:flex lg:flex-row-reverse lg:justify-between">
            <div>
              <div class="flex flex-wrap mb-8">
                <a :href="instagramUrl" class="mr-3 hover:text-pink-500 transition-colors hover:cursor-pointer">
                  <Icon name="instagram" />
                </a>
                <a :href="facebookUrl" class="hover:text-pink-500 transition-colors hover:cursor-pointer">
                  <Icon name="facebook" />
                </a>
              </div>
            </div>
            <div class="lg:flex">
              <a
                :href="$getLocalizedUrl('privacy-policy')"
                class="block mb-4 text-sm text-gray-600 underline hover:cursor-pointer lg:mr-6"
              >
                {{ $trans('Footer.Privacy') }}
              </a>
              <a
                :href="$getLocalizedUrl('terms')"
                class="block mb-7 text-sm text-gray-600 underline hover:cursor-pointer lg:mr-6"
              >
                {{ $trans('Footer.Terms') }}
              </a>

              <div class="flex text-sm text-gray-600 relative">
                <div>&copy; {{ currentYear }} Rentbird</div>
                <div class="relative pl-4 top-[-2px]">
                  <LoadingSpinner :extra-small="true" :show-text="false" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  </footer>
</template>
