<script setup>
import { onMounted, ref, watch } from 'vue'
import Icon from '@js/Components/Icon/Icon.vue'
import CustomTransitionFade from '@js/Components/Transitions/CustomTransitionFade.vue'
import { useIsMobile } from '@js/App/Utils/BrowserDetection'

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true
  },
  closeable: {
    type: Boolean,
    default: true
  },
  mobileOnly: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:isOpen'])
const open = ref(props.isOpen)

watch(() => props.isOpen, (newValue) => {
  open.value = newValue
})

watch(open, (newOpen) => {
  emit('update:isOpen', newOpen)
})

const { isMobile } = useIsMobile()
const justGotMounted = ref(true)
onMounted(() => {
  justGotMounted.value = true
  setTimeout(() => { justGotMounted.value = false }, 1)
})

</script>
<template>
  <CustomTransitionFade v-if="open">
    <div v-if="isMobile" class="fixed w-screen h-screen top-0 left-0 lg:pointer-events-none z-50">
      <div
        class="flex flex-col w-full bg-purple-700 text-white fixed bottom-0 px-6 pb-10 rounded-t-lg lg:w-[30rem] lg:right-5 z-30 pointer-events-auto"
      >
        <div class="flex justify-between bg-red-500 cursor-pointer pt-6">
          <div class="w-full">
            <slot />
          </div>
          <Icon v-if="closeable" name="cross" class="" @click="open = false" />
        </div>
      </div>
      <div class="bg-black z-20 opacity-50 w-full h-full top-0 left-0 lg:hidden" @click="closeable && (open = false)" />
    </div>
    <div
      v-else-if="!isMobile && !mobileOnly"
      class="relative z-70 "
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <!-- This is the dark background on deskotp -->
      <div
        class="fixed inset-0 bg-black bg-opacity-50 transition-all duration-200"
        :class="{
          'bg-opacity-0': justGotMounted,
        }"
      />

      <!-- This is the modal -->
      <div class="fixed inset-0 flex justify-center items-center lg:py-10">
        <div
          v-bind="$attrs"
          class="bg-purple-700 overflow-y-auto h-auto max-h-[90vh] w-full lg:max-h-full lg:max-w-full lg:rounded-md transition-transform duration-500 text-white lg:w-[400px] mr-[16px] ml-[16px] rounded-md p-4 lg:p-6"
          :class="{
            'translate-y-vscreen': justGotMounted
          }"
        >
          <div v-if="closeable" class="flex justify-end">
            <Icon name="cross" class="text-white transition-colors hover:cursor-pointer" @click="open = false" />
          </div>
          <slot />
        </div>
      </div>
    </div>
  </CustomTransitionFade>
</template>
