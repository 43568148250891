<script setup>
import B2BLayout from '@js/Components/B2B/B2BLayout.vue'
import { usePage, Link } from '@inertiajs/vue3'
import { useB2BOrganization } from '@js/App/Api/B2B/B2B'
import MyRentbirdCard from '@js/Components/MyRentbird/MyRentbirdCard/MyRentbirdCard.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import DataTable from '@js/Components/Tables/DataTable.vue'
import HouseStatusDropdown from '@js/Components/B2B/HouseStatusDropdown.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { computed } from 'vue'
import { ziggyRoute } from '@js/App/Utils/ZiggyUrls'

const page = usePage()

const house = computed(() => (page.props.house))
const usersThatReceivedHouse = computed(() => page.props.users_that_received_this_house)

const { organization } = useB2BOrganization()

</script>
<template>
  <B2BLayout
    :bread-crumbs="[
      {
        label: 'Houses',
        url: ziggyRoute('b2b.houses', { organization: organization})
      },
      {
        label: house.name,
        url: ziggyRoute('b2b.house.details', { organization: organization, house: house})
      }
    ]"
  >
    <template #title>
      <div class="flex items-center">
        <Icon name="house" icon-width="w-[40px] mr-2 relative top-[-2px]" />
        <span>{{ house.name }}</span>
      </div>
    </template>

    <MyRentbirdCard class="mt-6" :can-expand-on-mobile="false">
      <template #title>
        House
      </template>
      <div class="flex">
        <div class="w-1/2">
          <img :src="house.image_url">
        </div>
        <div class="w-1/2">
          <table>
            <tr>
              <td class="font-bold px-[8px] py-[4px]">
                Name
              </td>
              <td class="px-[8px] py-[4px]">
                {{ house.name }}
              </td>
            </tr>
            <tr>
              <td class="font-bold px-[8px] py-[4px]">
                Formatted Address
              </td>
              <td class="px-[8px] py-[4px]">
                {{ house.formatted_address }}
              </td>
            </tr>
            <tr>
              <td class="font-bold px-[8px] py-[4px]">
                Details
              </td>
              <td class="px-[8px] py-[4px]">
                €{{ $formatNumber(house.rent, 0) }} | {{ house.area || '?' }}m2 | {{ house.bedrooms || '?' }} bedrooms
              </td>
            </tr>
            <tr>
              <td class="font-bold px-[8px] py-[4px]">
                Location
              </td>
              <td class="px-[8px] py-[4px]">
                {{ house.city }} - {{ house.neighbourhood }}
              </td>
            </tr>
            <tr>
              <td class="font-bold px-[8px] py-[4px]">
                Found at
              </td>
              <td class="px-[8px] py-[4px]">
                {{ house.created_at_formatted_diff }}
              </td>
            </tr>
          </table>
          <HouseStatusDropdown
            class="mt-3 mb-4"
            :house-random-id="house.random_id"
            :house-status="house.house_status"
          />
          <div class="w-full flex ">
            <Button
              class="mt-4"
              :big="true"
              :outline="true"
              tag="a"
              :href="house.detail_url"
              target="_blank"
              icon-right="external"
            >
              Go to house
            </Button>
          </div>
        </div>
      </div>
    </MyRentbirdCard>

    <MyRentbirdCard class="mt-6" :can-expand-on-mobile="false">
      <template #title>
        Users that received this house
      </template>
      <DataTable
        :headers="[
          {
            label: 'Name',
            value: 'name',
            isSortable: false,
          },
          {
            label: 'Email',
            value: 'email',
            isSortable: false,
          },
          {
            label: 'Received At',
            value: 'created_at',
            isSortable: false,
          },
        ]"
      >
        <template
          v-for="item of usersThatReceivedHouse"
          :key="item?.random_id"
        >
          <tr class="row">
            <td
              v-tippy="{
                allowHTML: true,
                content: `<b>Key info</b><p>Income: ${item.personal_b2b_data?.monthly_income}</p><p>Work: ${item.personal_b2b_data?.work_situation}</p><p>Must have: ${item.personal_b2b_data?.must_have}</p>`,
              }"
            >
              <Link
                class="text-blue-base hover:cursor-pointer hover:underline"
                :href="ziggyRoute('b2b.user.details', { organization: organization, user: item.random_id })"
              >
                <!-- {{ item.personal_b2b_data }} -->
                {{ item.firstname }} {{ item.lastname }}
              </Link>
            </td>
            <td>{{ item.email }}</td>
            <td>{{ item.created_at }}</td>
          </tr>
        </template>
      </DataTable>
    </MyRentbirdCard>
  </B2BLayout>
</template>
<style lang="scss">
.B2B-HouseDetailPage {
  background: rgb(234,234,235);
}
</style>
