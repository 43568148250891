<script setup>
import PricingCardAlternativeAmount
  from '@js/Components/PricingCard/PricingCardAlternative/PricingCardAlternativeAmount.vue'
import { useUser } from '@js/App/Api/User/User'
import Toggle from '@js/Components/Forms/Toggle/Toggle.vue'
import { computed, ref } from 'vue'
import CheckmarkItem from '@js/Components/PricingCard/PricingCardCurrentSubscription/CheckmarkItem.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { useMainStore } from '@js/App/Stores/MainStore'
import { doPostRequest } from '@js/App/Api/Infra/ApiClient'
import { reloadPropsFromServer } from '@js/App/Api/Infra/ServerData'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import { router } from '@inertiajs/vue3'
import CustomTransitionFade from '@js/Components/Transitions/CustomTransitionFade.vue'
import { getFullPricePerMonth } from '@js/App/Api/Payments'

const { userActivePayment, user } = useUser()

const internalEnabledAutoRenew = ref(user.value.enabledAutoRenew)

const shouldShowChangesBox = computed(() => internalEnabledAutoRenew.value !== user.value.enabledAutoRenew)

const clickedGoBack = () => {
  internalEnabledAutoRenew.value = user.value.enabledAutoRenew
}

const mainStore = useMainStore()
const clickedSaveChanges = async () => {
  mainStore.startLoading()

  await doPostRequest('/api/user/subscription', {
    auto_renew: internalEnabledAutoRenew.value
  })

  const shouldGoIntoOffboardingFlow = !internalEnabledAutoRenew.value
  if (shouldGoIntoOffboardingFlow) {
    router.visit(getLocalizedUrl('my-rentbird.offboarding'))
    return
  }

  reloadPropsFromServer()
  mainStore.stopLoading()
}

const fullPrice = computed(() => {
  if (isSpecialTrialPlan.value) {
    return 2900
  }

  if (userActivePayment.value.priceDto.numberOfMonths > 1) {
    return getFullPricePerMonth()
  }

  return false
})

const price = computed(() => {
  const priceDto = userActivePayment.value.priceDto

  if (isSpecialTrialPlan.value) {
    return priceDto.amount
  }
  return priceDto.amount / priceDto.numberOfMonths
})

const discount = computed(() => {
  if (isSpecialTrialPlan.value) {
    return 86
  }

  const priceDto = userActivePayment.value.priceDto

  if (priceDto.numberOfMonths === 1) {
    return 0
  }

  if (priceDto.numberOfMonths === 2) { return 32 }

  return 44
})

const isSpecialTrialPlan = computed(() => {
  const priceDto = userActivePayment.value.priceDto
  return priceDto.numberOfMonths <= 0
})
</script>
<template>
  <div class="relative">
    <div class="bg-purple-900 text-white px-6 py-4 rounded-md lg:py-6 w-[500px] lg:w-[400px] xl:w-[500px] max-w-full">
      <div class="text-white font-medium text-xl text-center lg:text-3xl">
        {{ $trans(isSpecialTrialPlan ? 'PricingCardCurrentSubscription.PerWeek' : 'PricingCardCurrentSubscription.PerMonths', {
          months: userActivePayment.priceDto.numberOfMonths
        }) }}
      </div>
      <PricingCardAlternativeAmount
        class="mt-[16px]"
        :full-price="fullPrice"
        :discount="discount"
        :price="price"
      />

      <div v-if="userActivePayment.payment_method === 'apple_in_app'" class="flex w-full justify-center mt-[24px] lg:mt-[24px] bg-pink-500 rounded-lg px-4 py-0.5 text-center">
        {{ $trans('PricingCarcChangeSubscription.NoAutoRenewApple') }}
      </div>
      <div v-else class="flex w-full justify-center mt-[24px] lg:mt-[24px]">
        <Toggle
          v-model="internalEnabledAutoRenew"
          :gray-on-false="true"
          :options="[ {value:false}, {value: true}]"
        />
        {{ $trans('PricingCarcChangeSubscription.AutoRenew') }}
      </div>

      <div class="text-xs lg:text-sm text-gray-50 text-center mt-[8px] lg:mt-[12px]">
        <span v-if="user.enabledAutoRenew">
          {{ $trans('PricingCardCurrentSubscription.NextInvoiceAt', { date: userActivePayment.subscription_ends_at_formatted}) }}
        </span>
        <span v-else>
          {{ $trans('PricingCardCurrentSubscription.StopsAt', { date: userActivePayment.subscription_ends_at_formatted}) }}
        </span>
      </div>
    </div>

    <CustomTransitionFade>
      <div
        v-if="shouldShowChangesBox"
        class="absolute left-[-16px] right-[-16px] xl:left-[100%] xl:right-auto min-[1024px]:w-[400px] min-[1100px]:w-[490px] min-[1210px]:w-[600px] min-[1280px]:w-[636px] xl:top-0 xl:z-10 xl:bottom-0"
      >
        <div class="xl:hidden flex justify-center relative top-[-10px] mb-[-10px]">
          <svg xmlns="http://www.w3.org/2000/svg" width="49" height="25" viewBox="0 0 49 25" fill="none">
            <path d="M24.5 -1.07093e-06L49 24.5L-1.07093e-06 24.5L24.5 -1.07093e-06Z" fill="#E0E0E1" />
          </svg>
        </div>
        <div class="xl:flex xl:items-center xl:h-full">
          <svg
            class="hidden xl:block"
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="49"
            viewBox="0 0 25 49"
            fill="none"
          >
            <path d="M0 24.5L24.5 0V49L0 24.5Z" fill="#E0E0E1" />
          </svg>
          <div class="bg-gray-100 rounded-md px-[24px] py-[24px] text-black text-base relative top-[-12px] xl:top-0 xl:left-[-2px] xl:flex-grow xl:self-stretch mt-2 xl:flex xl:flex-col xl:justify-center">
            <h4 class="font-medium text-lg text-purple-900 mb-[12px] xl:text-xl">
              {{ $trans('PricingCardCurrentSubscription.AboutYourChange') }}
            </h4>

            <CheckmarkItem v-if="!user.enabledAutoRenew" class="mb-[16px]">
              {{ $trans('PricingCardCurrentSubscription.WeRenewSubscriptionAt', { date: userActivePayment.subscription_ends_at_formatted}) }}
            </CheckmarkItem>
            <CheckmarkItem v-if="!user.enabledAutoRenew" class="mb-[16px]">
              {{ $trans('PricingCardCurrentSubscription.2DaysBeforeRenewalReminder') }}
            </CheckmarkItem>
            <CheckmarkItem v-if="user.enabledAutoRenew" class="mb-[16px]">
              {{ $trans('PricingCardCurrentSubscription.WeStopSubscriptionAt', { date: userActivePayment.subscription_ends_at_formatted}) }}
            </CheckmarkItem>
            <CheckmarkItem v-if="user.enabledAutoRenew" class="mb-[16px]">
              {{ $trans('PricingCardCurrentSubscription.YouCanKeepUsingRentbird') }}
            </CheckmarkItem>

            <div class="lg:flex lg:flex-row-reverse">
              <Button
                :big="true"
                icon-right="save"
                class="mb-[12px] lg:w-1/2 lg:ml-[6px]"
                :full-width-on-mobile="true"
                :full-width-on-desktop="true"
                @click="clickedSaveChanges"
              >
                {{ $trans('PricingCardCurrentSubscription.SaveChanges') }}
              </Button>
              <Button
                :big="true"
                icon-right="cross"
                :outline="true"
                :full-width-on-mobile="true"
                :full-width-on-desktop="true"
                class="lg:w-1/2 lg:mr-[6px]"
                @click="clickedGoBack"
              >
                {{ $trans('Common.Cancel') }}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </CustomTransitionFade>
  </div>
</template>
