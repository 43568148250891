<script setup>

import { computed, reactive, ref } from 'vue'
import { router, usePage } from '@inertiajs/vue3'
import { useB2BOrganization } from '@js/App/Api/B2B/B2B'
import TextInput from '@js/Components/Forms/TextInput/TextInput.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { ziggyRoute } from '@js/App/Utils/ZiggyUrls'
import TextArea from '../Forms/TextArea/TextArea.vue'

const page = usePage()
const originalUser = computed(() => page.props.b2b_user)
const user = computed(() => page.props.b2b_user.personal_b2b_data)
const errors = computed(() => page.props.errors)

const form = reactive({
  phone: user.value ? user.value.phone : null,
  email: user.value ? user.value.email : null,
  current_address: user.value ? user.value.current_address : null,
  language: user.value ? user.value.language : null,
  will_live_together_with: user.value ? user.value.will_live_together_with : null,
  number_of_adults: user.value ? user.value.number_of_adults : null,
  number_of_kids: user.value ? user.value.number_of_kids : null,
  pets: user.value ? user.value.pets : null,
  birthdays: user.value ? user.value.birthdays : null,
  work_situation: user.value ? user.value.work_situation : null,
  monthly_income: user.value ? user.value.monthly_income : null,
  guarantor: user.value ? user.value.guarantor : null,
  savings_50k_plus: user.value ? user.value.savings_50k_plus : null,
  permanent_temp_contract: user.value ? user.value.permanent_temp_contract : null,
  why_move: user.value ? user.value.why_move : null,
  preferred_move_in_date: user.value ? user.value.preferred_move_in_date : null,
  rental_budget: user.value ? user.value.rental_budget : null,
  location: user.value ? user.value.location : null,
  looking_for_property_type: user.value ? user.value.looking_for_property_type : null,
  must_have: user.value ? user.value.must_have : null,
  nice_to_have: user.value ? user.value.nice_to_have : null,
  other_comments: user.value ? user.value.other_comments : null
})

const { organization } = useB2BOrganization()
const showSavedMessage = ref(false)
const submit = () => {
  router.post(
    ziggyRoute('b2b.user.personal.details', {
      organization: organization.value,
      user: originalUser.value.random_id
    }),
    form,
    {
      preserveScroll: true,
      preserveState: true,
      onSuccess: () => {
        showSavedMessage.value = true
        setTimeout(() => {
          showSavedMessage.value = false
        }, 2000)
      }
    }
  )
}
</script>
<template>
  <form @submit.prevent="submit">
    <div class="flex space-x-6 mb-4">
      <TextInput
        v-model="form.phone"
        :error="errors.phone"
        class="w-1/2"
        placeholder="Phone"
      >
        <template #label>
          <span class="text-purple-900">Phone</span>
        </template>
      </TextInput>

      <TextInput
        v-model="form.email"
        :error="errors.email"
        placeholder="Email"
        class="w-1/2"
      >
        <template #label>
          <span class="text-purple-900">Email</span>
        </template>
      </TextInput>

      <TextInput
        v-model="form.current_address"
        :error="errors.current_address"
        class="w-1/2"
        placeholder="Current address"
      >
        <template #label>
          <span class="text-purple-900">Current address</span>
        </template>
      </TextInput>

      <TextInput
        v-model="form.language"
        :error="errors.language"
        class="w-1/2"
        placeholder="Language"
      >
        <template #label>
          <span class="text-purple-900">Language</span>
        </template>
      </TextInput>
    </div>

    <div class="flex space-x-6 mb-4">
      <TextInput
        v-model="form.will_live_together_with"
        :error="errors.will_live_together_with"
        class="w-1/2"
        placeholder="Will live together with"
      >
        <template #label>
          <span class="text-purple-900">Will live together with</span>
        </template>
      </TextInput>

      <TextInput
        v-model="form.number_of_adults"
        :error="errors.number_of_adults"
        class="w-1/2"
        placeholder="Number of adults"
      >
        <template #label>
          <span class="text-purple-900">Number of adults</span>
        </template>
      </TextInput>

      <TextInput
        v-model="form.number_of_kids"
        :error="errors.number_of_kids"
        class="w-1/2"
        placeholder="Number of kids"
      >
        <template #label>
          <span class="text-purple-900">Number of kids</span>
        </template>
      </TextInput>

      <TextInput
        v-model="form.pets"
        :error="errors.pets"
        class="w-1/2"
        placeholder="Pets"
      >
        <template #label>
          <span class="text-purple-900">Pets</span>
        </template>
      </TextInput>
    </div>

    <div class="flex space-x-6 mb-4">
      <TextInput
        v-model="form.birthdays"
        :error="errors.birthdays"
        class="w-1/2"
        placeholder="Birthdays"
      >
        <template #label>
          <span class="text-purple-900">Birthdays</span>
        </template>
      </TextInput>

      <TextInput
        v-model="form.work_situation"
        :error="errors.work_situation"
        class="w-1/2"
        placeholder="Work situation"
      >
        <template #label>
          <span class="text-purple-900">Work situation</span>
        </template>
      </TextInput>

      <TextInput
        v-model="form.savings_50k_plus"
        :error="errors.savings_50k_plus"
        class="w-1/2"
        placeholder="Savings 50k plus"
      >
        <template #label>
          <span class="text-purple-900">Savings 50k plus</span>
        </template>
      </TextInput>

      <TextInput
        v-model="form.guarantor"
        :error="errors.guarantor"
        class="w-1/2"
        placeholder="Guarantor"
      >
        <template #label>
          <span class="text-purple-900">Guarantor</span>
        </template>
      </TextInput>
    </div>

    <div class="flex space-x-6 mb-4">
      <TextInput
        v-model="form.preferred_move_in_date"
        :error="errors.preferred_move_in_date"
        class="w-1/2"
        placeholder="Preferred move in date"
      >
        <template #label>
          <span class="text-purple-900">Preferred move in date</span>
        </template>
      </TextInput>

      <TextInput
        v-model="form.rental_budget"
        :error="errors.rental_budget"
        class="w-1/2"
        placeholder="Rental budget"
      >
        <template #label>
          <span class="text-purple-900">Rental budget</span>
        </template>
      </TextInput>

      <TextInput
        v-model="form.looking_for_property_type"
        :error="errors.looking_for_property_type"
        class="w-1/2"
        placeholder="Looking for property type"
      >
        <template #label>
          <span class="text-purple-900">Looking for property type</span>
        </template>
      </TextInput>
    </div>

    <div class="flex space-x-6 mb-4">
      <TextArea
        v-model="form.monthly_income"
        :error="errors.monthly_income"
        class="w-1/2"
        placeholder="Monthly income"
        rows="3"
      >
        <template #label>
          <span class="text-purple-900">Monthly income</span>
        </template>
      </TextArea>

      <TextArea
        v-model="form.permanent_temp_contract"
        :error="errors.permanent_temp_contract"
        class="w-1/2"
        placeholder="Permanent temp contract"
        rows="3"
      >
        <template #label>
          <span class="text-purple-900">Permanent temp contract</span>
        </template>
      </TextArea>

      <TextArea
        v-model="form.why_move"
        :error="errors.why_move"
        class="w-1/2"
        placeholder="Why move"
        rows="3"
      >
        <template #label>
          <span class="text-purple-900">Why move</span>
        </template>
      </TextArea>

      <TextArea
        v-model="form.location"
        :error="errors.location"
        class="w-1/2"
        placeholder="Location"
        rows="3"
      >
        <template #label>
          <span class="text-purple-900">Location</span>
        </template>
      </TextArea>
    </div>

    <div class="flex space-x-6 mb-4">
      <TextArea
        v-model="form.must_have"
        :error="errors.must_have"
        class="w-1/2"
        placeholder="Must have"
        rows="4"
      >
        <template #label>
          <span class="text-purple-900">Must have</span>
        </template>
      </TextArea>

      <TextArea
        v-model="form.nice_to_have"
        :error="errors.nice_to_have"
        class="w-1/2"
        placeholder="Nice to have"
        rows="4"
      >
        <template #label>
          <span class="text-purple-900">Nice to have</span>
        </template>
      </TextArea>

      <TextArea
        v-model="form.other_comments"
        :error="errors.other_comments"
        class="w-1/2"
        placeholder="Other comments"
        rows="4"
      >
        <template #label>
          <span class="text-purple-900">Other comments</span>
        </template>
      </TextArea>
    </div>

    <div>
      <Button
        :big="true"
        :outline="true"
        :icon-right="showSavedMessage ? 'check' : 'save'"
        @click="submit"
      >
        <span v-if="showSavedMessage">Saved</span>
        <span v-else>Save changes</span>
      </Button>
    </div>
  </form>
</template>
