<script setup>
import { ref } from 'vue'
import { useUser } from '@js/App/Api/User/User'
import { trans } from '@js/App/Plugins/Translations'
import { saveNotificationChannel } from '@js/App/Api/User/NotificationChannel'
import Radiobutton from '@js/Components/Forms/Radiobutton/Radiobutton.vue'

defineProps({
  classLabel: {
    type: String,
    default: ''
  },
  showDownloadAppLabel: {
    type: Boolean,
    default: true
  }
})

const { user } = useUser()
const internalNotificationChannel = ref(user.value.notificationChannel)

const errors = ref({})
const changeNotificationChannel = () => {
  return saveNotificationChannel(errors, internalNotificationChannel)
}
const emit = defineEmits(['update:notificationChannel'])
</script>
<template>
  <div>
    <Radiobutton
      class="mt-[16px]"
      :model-value="internalNotificationChannel === 'mobile'"
      @update:model-value="newValue => {
        if (internalNotificationChannel !== 'mobile') {
          internalNotificationChannel = 'mobile'
          changeNotificationChannel()
          emit('update:notificationChannel', 'mobile')
        }
      }"
    >
      {{ trans('Common.MobileApp') }}
    </Radiobutton>
    <Radiobutton
      class="mt-[16px]"
      :model-value="internalNotificationChannel === 'email'"
      @update:model-value="newValue => {
        if (internalNotificationChannel !== 'email') {
          internalNotificationChannel = 'email'
          changeNotificationChannel()
          emit('update:notificationChannel', 'email')
        }
      }"
    >
      {{ trans('StepDownloadApp.EmailMethodDisclaimer') }}
    </Radiobutton>
  </div>
</template>
