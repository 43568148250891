<script setup>
import Select from '@js/Components/Forms/Select/Select.vue'
import { ref, watch } from 'vue'
import { useB2BOrganization } from '@js/App/Api/B2B/B2B'
import { doPostRequest } from '@js/App/Api/Infra/ApiClient'
import { ziggyRoute } from '@js/App/Utils/ZiggyUrls'

const props = defineProps({
  agentId: {
    type: String,
    default: ''
  },
  agentStatus: {
    type: String,
    default: ''
  }
})

const { organization } = useB2BOrganization()
const status = ref(props.agentStatus)

watch(status, async () => {
  await doPostRequest(ziggyRoute('b2b.agent.update-status', {
    organization: organization.value,
    agent: props.agentId
  }), { agent_status: status.value }, false)
})
</script>
<template>
  <div>
    <Select
      v-model="status"
      :options="[
        { value: false, label: `Not blocking` },
        { value: true, label: `Blocking` },
      ]"
    />
  </div>
</template>
