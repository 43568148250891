<script setup>
import { Link } from '@inertiajs/vue3'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import Icon from '@js/Components/Icon/Icon.vue'

const props = defineProps({
  post: Object,
  horizontal: Boolean
})

const minutedReadingTime = Math.ceil(
  props.post.content.flatMap(
    content =>
      Object.keys(content.attributes).map(key => content.attributes[key]?.length || 0)
  ).reduce((acc, el) => acc + el, 0) / 600
)

const titleComp = props.post.content.find(el => el.layout === 'App_Services_Blog_Segments_Title')
</script>

<template>
  <article
    class="grid grid-cols-1 grid-rows-[auto_1fr] rounded-md bg-[#EDEAFA]"
    :class="{
      'flex-col': !horizontal,
      'flex-row lg:grid-cols-5 lg:grid-rows-1': horizontal
    }"
  >
    <div class="lg:col-span-2 lg:col-start-1">
      <img
        class="w-full aspect-video h-full object-cover"
        :class="{
          'rounded-t-md lg:rounded-none lg:rounded-l-md lg:aspect-auto': horizontal,
          'rounded-t-md': !horizontal,
        }"
        :src="post.image_url"
        :alt="post.meta_image_alt"
      >
    </div>
    <div
      class="row-start-2 flex flex-col gap-4 p-6"
      :class="{
        'lg:py-20 lg:px-10 lg:col-start-3 lg:col-span-3 lg:row-start-1': horizontal,
      }"
    >
      <div class="flex gap-2 flex-wrap">
        <span v-for="tag in post.tags" :key="tag.id" class="bg-purple-800 text-white p-1 px-3 rounded-full text-sm inline-block self-center">
          {{ tag.name }}
        </span>
        <div v-if="minutedReadingTime > 0">
          {{ $trans('OnboardingTipsPage.MinutesReadingTime', { minutes: minutedReadingTime }) }}
        </div>
      </div>

      <div class="flex-grow">
        <h2 class="text-3xl font-bold mb-2">
          <Link :href="getLocalizedUrl('blog-post', { slug: post.slug })">
            {{ post.meta_title }}
          </Link>
        </h2>
        <p class="line-clamp-5" v-html="titleComp?.attributes?.content ?? post.meta_description" />
      </div>

      <div>
        <Link class="text-pink-500 font-bold flex gap-2 mt-12" :href="getLocalizedUrl('blog-post', { slug: post.slug })">
          {{ $trans("Blog.ViewArticle") }} <Icon name="arrow-right" />
        </Link>
      </div>
    </div>
  </article>
</template>
