<script setup>
import { useMainStore } from '@js/App/Stores/MainStore'
import { storeToRefs } from 'pinia'
import Icon from '@js/Components/Icon/Icon.vue'
import Container from '@js/Components/Container/Container.vue'
import { useIsMobile } from '@js/App/Utils/BrowserDetection'

const mainStore = useMainStore()
const { successToastText } = storeToRefs(mainStore)
const { isMobile } = useIsMobile()

</script>
<template>
  <div
    v-if="successToastText"
    :class="[
      'fixed z-50 bg-good text-success text-base font-medium lg:text-lg rounded shadow-lg',
      isMobile ? 'bottom-0 left-0 right-0 w-full rounded-none' : 'bottom-4 right-4 min-w-80 max-w-96'
    ]"
  >
    <Container class="py-2 px-4">
      <div class="flex justify-between items-center">
        <div class="flex items-center">
          <Icon name="check" class="pr-2 lg:relative" />
          {{ successToastText }}
        </div>
      </div>
    </Container>
  </div>
</template>
