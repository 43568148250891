<script setup>
import Select from '@js/Components/Forms/Select/Select.vue'
import { ref, watch } from 'vue'
import TextInput from '@js/Components/Forms/TextInput/TextInput.vue'
import ErrorMessage from '@js/Components/Forms/ErrorMessage/ErrorMessage.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { useMainStore } from '@js/App/Stores/MainStore'
import { doPostRequest } from '@js/App/Api/Infra/ApiClient'
import SuccessMessage from '@js/Components/Forms/SuccessMessage/SuccessMessage.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import { useIsMobile } from '@js/App/Utils/BrowserDetection'
import { reloadPropsFromServer } from '@js/App/Api/Infra/ServerData'
import CustomTransitionExpand from '@js/Components/Transitions/CustomTransitionExpand.vue'
import { COUNTRY_NL, getCountry } from '@js/App/Utils/CountryAndLocale'

const props = defineProps({
  modelValue: String,

  requireValidation: {
    type: Boolean,
    default: true
  },

  error: {
    type: String,
    default: ''
  }
})

const countryCodeOptions = [
  { label: '(NL) +31', value: '+31' },
  { label: '(GR) +30', value: '+30' },
  { label: '(BE) +32', value: '+32' },
  { label: '(FR) +33', value: '+33' },
  { label: '(ES) +34', value: '+34' },
  { label: '(PT) +351', value: '+351' },
  { label: '(LU) +352', value: '+352' },
  { label: '(IE) +353', value: '+353' },
  { label: '(IS) +354', value: '+354' },
  { label: '(AL) +355', value: '+355' },
  { label: '(MT) +356', value: '+356' },
  { label: '(CY) +357', value: '+357' },
  { label: '(AT) +43', value: '+43' },
  { label: '(BG) +359', value: '+359' },
  { label: '(HR) +385', value: '+385' },
  { label: '(CZ) +420', value: '+420' },
  { label: '(DK) +45', value: '+45' },
  { label: '(EE) +372', value: '+372' },
  { label: '(EE) +372', value: '+372' },
  { label: '(FI) +358', value: '+358' },
  { label: '(DE) +49', value: '+49' },
  { label: '(HU) +36', value: '+36' },
  { label: '(IT) +39', value: '+39' },
  { label: '(LV) +371', value: '+371' },
  { label: '(LT) +370', value: '+370' },
  { label: '(PL) +48', value: '+48' },
  { label: '(RO) +40', value: '+40' },
  { label: '(SK) +421', value: '+421' },
  { label: '(SI) +386', value: '+386' },
  { label: '(SE) +46', value: '+46' },
  { label: '(AD) +376', value: '+376' },
  { label: '(AM) +374', value: '+374' },
  { label: '(BY) +375', value: '+375' },
  { label: '(BA) +387', value: '+387' },
  { label: '(XK) +381', value: '+381' },
  { label: '(MK) +389', value: '+389' },
  { label: '(MD) +373', value: '+373' },
  { label: '(MC) +377', value: '+377' },
  { label: '(ME) +382', value: '+382' },
  { label: '(NO) +47', value: '+47' },
  { label: '(RU) +7', value: '+7' },
  { label: '(RS) +381', value: '+381' },
  { label: '(CH) +41', value: '+41' },
  { label: '(TR) +90', value: '+90' },
  { label: '(UA) +380', value: '+380' },
  { label: '(GB) +44', value: '+44' },
  { label: '(US/CA) +1', value: '+1' },
  { label: '(MX) +52', value: '+52' },
  { label: '(TR) +90', value: '+90' },
  { label: '(IN) +91', value: '+91' },
  { label: '(MA) +212', value: '+212' },
  { label: '(MN) +976', value: '+976' }
].sort((a, b) => a.label < b.label ? -1 : 1)

const defaultCountryCode = getCountry() === COUNTRY_NL ? '+31' : '+1'

const getCountryCodeFromModel = () => countryCodeOptions.find(item => props.modelValue?.startsWith(item.value))?.value || defaultCountryCode
const getPhoneFromModel = () => props.modelValue?.replace(countryCode.value, '')

const countryCode = ref(getCountryCodeFromModel())
const phone = ref(getPhoneFromModel())

const initialModelValue = `${props.modelValue}`

const STEP_SHOW_PHONE_INPUT = 'phone-input'
const STEP_SHOW_SEND_SMS = 'send-sms'
const STEP_SHOW_VERIFICATION_INPUT = 'verification'
const STEP_VERIFICATION_COMPLETE = 'complete'
const step = ref(STEP_SHOW_PHONE_INPUT)

watch(() => props.modelValue, () => {
  if (!props.requireValidation) {
    step.value = STEP_SHOW_PHONE_INPUT
    return
  }
  if (props.modelValue !== initialModelValue) {
    step.value = STEP_SHOW_SEND_SMS
    emit('update:has-changed-phone', true)
  } else {
    step.value = STEP_SHOW_PHONE_INPUT
    emit('update:has-changed-phone', false)
  }
})

const mainStore = useMainStore()
const internalErrors = ref({})
const hasResendCode = ref(false)

const onClickSendVerificationCode = async (isResend = false) => {
  mainStore.startLoading()
  internalErrors.value = {}
  try {
    const response = await doPostRequest('/api/user/phone/verification-code', {
      phone: props.modelValue
    })
    if (response.hasErrors) {
      internalErrors.value = response.errors
      return
    }

    step.value = STEP_SHOW_VERIFICATION_INPUT
    if (isResend) {
      hasResendCode.value = true
    }
  } finally {
    mainStore.stopLoading()
  }
}

const submitVerificationCode = async () => {
  mainStore.startLoading()
  internalErrors.value = {}
  try {
    const response = await doPostRequest('/api/user/phone/validate-and-update', {
      phone: props.modelValue,
      validation_code: verificationCode.value
    })
    if (response.hasErrors) {
      internalErrors.value = response.errors
      return
    }

    step.value = STEP_VERIFICATION_COMPLETE
    emit('update:has-saved-new-phone', true)

    reloadPropsFromServer()
  } finally {
    mainStore.stopLoading()
  }
}

watch(() => props.modelValue, () => {
  internalErrors.value = {}
})

const emit = defineEmits(['update:modelValue', 'update:hasChangedPhone', 'update:hasSavedNewPhone'])

const verificationCode = ref('')

const { isMobile } = useIsMobile()
</script>
<template>
  <div>
    <label class="block font-medium text-base mb-1 text-purple-700">
      {{ $trans('PhoneNumberInput.Label') }}
    </label>

    <div class="flex flex-wrap lg:flex-nowrap">
      <Select
        :model-value="countryCode"
        :options="countryCodeOptions"
        :show-error-border="!!error || !!internalErrors.phone"
        class="w-1/3 mr-[4px]"
        @update:model-value="newCountryCode => {
          countryCode = newCountryCode
          console.log(newCountryCode)
          $emit('update:modelValue', `${newCountryCode}${phone}`)
        }"
      />
      <TextInput
        class="flex-grow"
        :model-value="phone"
        :show-error-border="!!error || !!internalErrors.phone"
        @update:model-value="newPhone => {
          phone = newPhone
          $emit('update:modelValue', `${countryCode}${newPhone}`)
        }"
      />
      <CustomTransitionExpand>
        <Button
          v-if="step === STEP_SHOW_SEND_SMS"
          class="flex-grow lg:flex-grow-0 mt-[6px] lg:mt-0 lg:ml-[4px]"
          :full-width-on-mobile="true"
          :outline="true"
          :big="!isMobile"
          icon-right="arrow-right"
          @click="onClickSendVerificationCode(false)"
        >
          {{ $trans('PhoneNumberInput.SendVerificationCodeButtonLabel') }}
        </Button>
      </CustomTransitionExpand>
    </div>
    <CustomTransitionExpand>
      <div v-if="step === STEP_SHOW_VERIFICATION_INPUT">
        <div>
          <div class="text-sm mt-[4px]">
            <Icon name="check" icon-width="inline w-[17px] h-[17px]" />
            {{ $trans('PhoneNumberInput.CodeSendToYourPhone') }}
            <a v-if="!hasResendCode" href="#" class="text-blue-base hover:cursor-pointer hover:underline" @click.prevent="onClickSendVerificationCode(true)">
              {{ $trans('PhoneNumberInput.Resend') }}
            </a>
            <p v-else class="text-success">
              {{ $trans('PhoneNumberInput.HasResend') }}
            </p>
          </div>
        </div>
        <div class="flex items-center">
          <TextInput
            v-model="verificationCode"
            :placeholder="$trans('PhoneNumberInput.VerificationCode')"
            class="flex-grow lg:flex-grow-0 mt-[6px]"
            :show-error-border="!!internalErrors.validation_code"
          />
          <Button :outline="true" :big="true" class="mt-[5px] ml-[4px]" icon-right="arrow-right" @click="submitVerificationCode">
            {{ $trans('Common.Submit') }}
          </Button>
        </div>
        <ErrorMessage v-if="!!internalErrors.validation_code" class="mt-[2px]">
          {{ internalErrors.validation_code }}
        </ErrorMessage>
      </div>

      <div v-if="step === STEP_VERIFICATION_COMPLETE">
        <SuccessMessage class="mt-[4px]">
          {{ $trans('PhoneNumberInput.PhoneVerified') }}
        </SuccessMessage>
      </div>
    </CustomTransitionExpand>

    <ErrorMessage v-if="error || internalErrors.phone" class="mt-[2px]">
      {{ error || internalErrors.phone }}
    </ErrorMessage>
  </div>
</template>
