<script setup>
import ErrorMessage from '@js/Components/Forms/ErrorMessage/ErrorMessage.vue'
import { onMounted, ref } from 'vue'
import { isSafari, useIsMobile } from '@js/App/Utils/BrowserDetection'

const props = defineProps({
  modelValue: String,
  placeholder: String,

  type: {
    type: String,
    default: 'text'
  },
  useLandingPageStyling: {
    type: Boolean,
    default: false
  },
  error: {
    type: String,
    default: ''
  },
  autocomplete: {
    type: String,
    default: ''
  },
  delayFocus: {
    type: Boolean,
    default: false
  },
  delayFocusTimeInMs: {
    type: Number,
    default: 500
  },
  disableBorder: {
    type: Boolean,
    default: false
  },
  id: {
    type: String,
    default: ''
  },
  showErrorBorder: {
    type: Boolean,
    default: false
  },
  blackBorder: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  disabledFaded: {
    type: Boolean,
    default: false
  },
  dark: {
    type: Boolean,
    default: false
  },

  // Tag used by Dusk browser tests, added directly to the input element
  dusk: {
    type: [String, null],
    default: null
  }
})

const emit = defineEmits(['update:modelValue', 'focus', 'blur', 'keyup.enter'])

const inputRef = ref(null)

const focus = () => {
  inputRef.value.focus()
}

defineExpose({
  focus
})

const onMouseDown = (e) => {
  const { isMobile } = useIsMobile()
  if (!props.delayFocus) { return }
  // we cannot delay for mobile safari. If we do this then the keyboard doesn't show up anymore when clicking input elements that have a delay.
  if (isMobile && isSafari()) { return }

  e.preventDefault()

  setTimeout(() => {
    inputRef.value.focus()
  }, props.delayFocusTimeInMs)
}

const onFocus = (e) => {
  // We have to do this hacky thing to disable safari auto selecting the input element when the modal pops up.
  // If you remove this, open safari on a desktop and click on "start search" on a main landing page you will
  // see that the city input is opened. Which sucks.
  const timeSinceMounted = performance.now() - mountedAt
  if (isSafari() && timeSinceMounted <= 700) {
    return
  }

  emit('focus')
}

let mountedAt = null
onMounted(() => {
  mountedAt = performance.now()
})
</script>
<template>
  <div>
    <label
      v-if="$slots.label"
      class="block font-medium text-base mb-1"
      :class="{
        'text-purple-700': !dark,
        'text-white': dark,
      }"
    >
      <slot name="label" />
    </label>
    <div
      class="relative"
    >
      <div
        v-if="$slots.icon"
        class="text-black absolute top-[15px] left-[16px]"
      >
        <slot name="icon" />
      </div>
      <div
        v-if="$slots.iconRight"
        class="text-black absolute top-[15px] right-[16px]"
      >
        <slot name="iconRight" />
      </div>
      <input
        :id="id || null"
        ref="inputRef"
        :type="type"
        :dusk="dusk"
        :value="modelValue"
        :autocomplete="autocomplete || null"
        :placeholder="placeholder"
        :disabled="disabled"
        class="block outline-0 ring-0 rounded focus:outline-0 focus-visible:outline-0 focus:ring-0 focus-visible:ring-0 active:outline-0 active:ring-0 w-full placeholder-gray-400 text-base"
        :class="{
          'bg-[#000] text-white p-[15px]': dark,
          'border border-black': blackBorder || dark,
          'pl-[52px]': $slots.icon,
          'pr-[52px]': $slots.iconRight,
          'p-[16px]': useLandingPageStyling,
          'border-none text-gray-900': useLandingPageStyling && !showErrorBorder && !blackBorder && !dark,
          'text-purple-700  p-[15px]': !useLandingPageStyling && !dark,
          'border border-gray-200': !disableBorder & !useLandingPageStyling && !error && !showErrorBorder && !dark,
          'border border-danger': (!disableBorder && !useLandingPageStyling && error) || showErrorBorder,
          'py-[15px]': useLandingPageStyling && showErrorBorder,
          'h-[150px]': disabledFaded,
          'hover:cursor-not-allowed': disabled,
        }"
        :style="useLandingPageStyling && !blackBorder && !dark && 'box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);'"
        @focus="(e) => onFocus(e)"
        @blur="$emit('blur')"
        @input="(e) => { $emit('update:modelValue', e.target.value) }"
        @keyup.enter="$emit('keyup.enter')"
        @mousedown="(e) => onMouseDown(e)"
      >
    </div>
    <ErrorMessage
      v-if="error"
      :dusk="`${dusk}-error`"
      class="mt-[2px]"
    >
      {{ error }}
    </ErrorMessage>
  </div>
</template>
