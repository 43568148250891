<script setup>
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import { Head, usePage } from '@inertiajs/vue3'
import * as Sentry from '@sentry/vue'
import { computed } from 'vue'

const page = usePage()

const queryParamsToAdd = {}
const hasPageQuery = page.props.route?.query?.page
if (hasPageQuery) {
  queryParamsToAdd.page = page.props.route.query.page
}

const locales = computed(() => page.props.routeLocales || [])
const routeName = computed(() => page.props.route?.name)
const routeParams = computed(() => page.props.route?.params)
const localeOverrides = computed(() => page.props.routeParamLocaleOverrides)
const altLinks = computed(() => page.props.altLinks)

const canGenerateAlternates = computed(() =>
  routeName.value != null &&
  routeParams.value != null &&
  locales.value.length > 0 &&
  localeOverrides.value != null
)

const localeAndLinks = computed(() => {
  if (altLinks.value) {
    return []
  }

  if (canGenerateAlternates.value) {
    return locales.value.map((locale) => {
      const overrides = localeOverrides.value[locale] || {}
      const href = getLocalizedUrl(routeName.value, {
        ...routeParams.value,
        ...overrides
      }, queryParamsToAdd, locale)

      return {
        hreflang: locale,
        href
      }
    })
  }

  // Handle cases where links cannot be generated
  const message = 'Could not generate alternate links, are the props properly set?'
  const hint = {
    locales: locales.value,
    props: page.props.route,
    overrides: { ...localeOverrides.value }
  }

  const isLikely404 = routeName.value == null
  const isLikelyPageWithoutLocales = locales.value.length === 0

  if (!(isLikely404 || isLikelyPageWithoutLocales)) {
    Sentry.captureException(new Error(message), hint)
  }

  return []
})
</script>

<template>
  <Head v-if="altLinks">
    <link v-for="locale in Object.keys(altLinks)" :key="locale" rel="alternate" :hreflang="locale" :href="altLinks[locale].href">
    <link
      v-if="altLinks.en"
      rel="alternate"
      hreflang="x-default"
      :href="altLinks.en.href"
    >
  </Head>
  <Head v-else>
    <link
      v-for="locale in localeAndLinks"
      :key="locale.hreflang"
      rel="alternate"
      :hreflang="locale.hreflang"
      :href="locale.href"
    >
    <link
      v-if="localeAndLinks.find(link => link.hreflang === 'en')"
      rel="alternate"
      hreflang="x-default"
      :href="localeAndLinks.find(link => link.hreflang === 'en').href"
    >
  </Head>
</template>
