<script setup>
import { ref } from 'vue'
import Icon from '@js/Components/Icon/Icon.vue'
import RentalChanceModal from '@js/Components/Modals/RentalChanceModal.vue'
import {
  RENTAL_CHANGE_HIGH,
  RENTAL_CHANGE_LOW,
  RENTAL_CHANGE_MEDIUM,
  RENTAL_CHANGE_SUPER
} from '@js/Components/HouseCards/MatchPageRentalChanceBar/MatchPageRentalChanceBar'
import { useIsMobile } from '@js/App/Utils/BrowserDetection'

defineProps({
  rentalChance: {
    type: String,
    default: RENTAL_CHANGE_SUPER
  }
})

const { isMobile } = useIsMobile()

const isModalOpen = ref(false)

const openModal = () => {
  isModalOpen.value = true
}
</script>
<template>
  <div
    v-tippy="{
      content: !isMobile ? $trans(`RentalChanceModal.${rentalChance}.body`) : '',
      allowHTML: true,
    }"
    class="rounded-t-lg px-[12px] py-[4px] lg:text-lg font-medium flex justify-between items-center"
    :class="{
      ['text-danger-soft bg-red-100']: rentalChance === RENTAL_CHANGE_LOW,
      ['text-[#E79F35] bg-orange-100']: rentalChance === RENTAL_CHANGE_MEDIUM,
      ['text-[#AFC429] bg-lime-100']: rentalChance === RENTAL_CHANGE_HIGH,
      ['text-[#00B67A] bg-green-100']: rentalChance === RENTAL_CHANGE_SUPER,
    }"
    @click="openModal"
  >
    <div class="mr-[4px] flex justify-center items-start">
      {{ $trans(`RentalChanceModal.${rentalChance}.title`) }} <Icon name="info" icon-width="w-[20px] h-[20px] " class="ml-[4px] relative top-[1px]" />
    </div>

    <svg
      v-if="rentalChance === RENTAL_CHANGE_LOW"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path opacity="0.2" d="M4.80078 4.80005L4.80078 19.2" stroke="#EF4444" stroke-width="3.6" stroke-linecap="round" />
      <path opacity="0.2" d="M12 4.80005L12 19.2" stroke="#EF4444" stroke-width="3.6" stroke-linecap="round" />
      <path opacity="0.2" d="M19.1992 4.80005L19.1992 19.2" stroke="#EF4444" stroke-width="3.6" stroke-linecap="round" />
    </svg>
    <svg
      v-if="rentalChance === RENTAL_CHANGE_MEDIUM"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path d="M4.80078 4.80005L4.80078 19.2" stroke="#E79F35" stroke-width="3.6" stroke-linecap="round" />
      <path opacity="0.3" d="M12 4.80005L12 19.2" stroke="#E79F35" stroke-width="3.6" stroke-linecap="round" />
      <path opacity="0.3" d="M19.1992 4.80005L19.1992 19.2" stroke="#E79F35" stroke-width="3.6" stroke-linecap="round" />
    </svg>
    <svg
      v-if="rentalChance === RENTAL_CHANGE_HIGH"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path d="M4.80078 4.80005L4.80078 19.2" stroke="#AFC429" stroke-width="3.6" stroke-linecap="round" />
      <path d="M12 4.80005L12 19.2" stroke="#AFC429" stroke-width="3.6" stroke-linecap="round" />
      <path opacity="0.3" d="M19.1992 4.80005L19.1992 19.2" stroke="#AFC429" stroke-width="3.6" stroke-linecap="round" />
    </svg>
    <svg
      v-if="rentalChance === RENTAL_CHANGE_SUPER"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path d="M4.80078 4.80005L4.80078 19.2" stroke="#00B67A" stroke-width="3.6" stroke-linecap="round" />
      <path d="M12 4.80005L12 19.2" stroke="#00B67A" stroke-width="3.6" stroke-linecap="round" />
      <path d="M19.1992 4.80005L19.1992 19.2" stroke="#00B67A" stroke-width="3.6" stroke-linecap="round" />
    </svg>
  </div>
  <RentalChanceModal v-if="isMobile" v-model="isModalOpen" />
</template>
