<script setup>
import SearchProfileCardLabel from '@js/Components/SearchProfileCard/SearchProfileCardLabel.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import { capitalizeFirstLetter } from '@js/App/Utils/StringFormatting'
import { getCurrency } from '@js/App/Utils/CountryAndLocale'

import { bookmarkHouse, unbookmarkHouse, reactToHouse, unreactToHouse } from '@js/App/Api/User/Houses'

const props = defineProps({
  houseId: Number,
  image: String,
  name: String,
  rentalAgent: String,
  foundAtFormatted: String,
  city: String,
  bedrooms: Number,
  area: Number,
  rent: Number,
  showViewedLabel: Boolean,
  hasViewed: Boolean,
  isHistoric: Boolean,
  url: String,
  isBookmarked: Boolean,
  isReacted: Boolean
})

const bookmark = async (e) => {
  e.preventDefault()

  props.isBookmarked ? await unbookmarkHouse(props.houseId) : await bookmarkHouse(props.houseId)
}

const react = async (e) => {
  e.preventDefault()

  props.isReacted ? await unreactToHouse(props.houseId) : await reactToHouse(props.houseId)
}

</script>

<template>
  <component
    :is="'a'"
    class="relative block group hover:cursor-pointer transition-colors flex flex-col h-full"
    :href="url"
  >
    <div class="relative">
      <img :src="image" alt="house image" class="rounded-t-md w-full h-[116px] lg:h-[235px] object-cover">
      <SearchProfileCardLabel class="m-2 absolute bottom-0 left-0 cursor-pointer" @click="react">
        <div class="flex items-center">
          <span v-if="isReacted" class="text-pink-500 font-medium text-sm mr-2">{{ $trans('RemoveReaction') }}</span><span v-else class="text-pink-500 font-medium text-sm mr-2">{{ $trans('AddReaction') }}</span> <Icon :name="isReacted ? 'folder-minus' : 'folder-plus'" icon-width="w-[24px] h-[24px]" class="text-pink-500" />
        </div>
      </SearchProfileCardLabel>
      <SearchProfileCardLabel class="m-2 absolute bottom-0 right-0 cursor-pointer" @click="bookmark">
        <div class="flex">
          <Icon :name="isBookmarked ? 'heart' : 'heart-light'" icon-width="w-[24px] h-[24px]" class="text-pink-500" />
        </div>
      </SearchProfileCardLabel>
    </div>

    <div
      v-if="showViewedLabel && !isHistoric"
      :class="{ [`bg-purple-500`]: hasViewed, [`bg-pink-500`]: !hasViewed }"
      class="absolute top-0 rounded-full text-white text-sm lg:text-base px-3 py-1 m-2"
    >
      {{ hasViewed ? $trans('MatchHistoryCard.Viewed') : $trans('MatchHistoryCard.New') }}
    </div>
    <div
      v-else-if="showViewedLabel && isHistoric && hasViewed"
      class="bg-purple-500 absolute top-0 rounded-full text-white text-sm lg:text-base px-3 py-1 m-2"
    >
      {{ $trans('MatchHistoryCard.Viewed') }}
    </div>
    <div
      v-else-if="showViewedLabel && isHistoric"
      v-tippy="{
        content: $trans(`MatchHistoryCard.OldTooltip`),
        allowHTML: true
      }"
      class="bg-gray-500 absolute top-0 rounded-full text-white text-sm lg:text-base px-3 py-1 m-2"
    >
      {{ $trans('MatchHistoryCard.Old') }}
    </div>

    <div
      class="bg-blue-50 group-hover:bg-blue-200 transition-colors px-[8px] py-[8px] rounded-b-md lg:px-[16px] lg:py-[16px] flex-grow"
    >
      <h4 class="text-base text-purple-900 font-medium lg:mb-[2px]">
        {{ name }}
      </h4>
      <p class="text-sm text-gray-800 lg:mb-[2px]">
        {{ foundAtFormatted }}
        <svg
          class="inline"
          xmlns="http://www.w3.org/2000/svg"
          width="4"
          height="4"
          viewBox="0 0 4 4"
          fill="none"
        >
          <circle cx="2" cy="2" r="2" fill="#3A3B3B" />
        </svg>
        {{ rentalAgent }}
      </p>

      <div class="flex flex-wrap">
        <SearchProfileCardLabel class="mr-[4px] mt-[4px]">
          <div class="flex">
            <Icon name="location" icon-width="w-[20px] h-[20px] " class="mr-[4px] relative top-[1px]" />
            <span class="text-sm text-purple-900">{{ capitalizeFirstLetter(city) }}</span>
          </div>
        </SearchProfileCardLabel>

        <SearchProfileCardLabel class="mr-[4px] mt-[4px]">
          <div class="flex">
            <Icon name="bed-alternative" icon-width="w-[20px] h-[20px] " class="mr-[4px] relative top-[1px]" />
            <span class="text-sm text-purple-900">{{ bedrooms }}</span>
          </div>
        </SearchProfileCardLabel>

        <SearchProfileCardLabel class="mr-[4px] mt-[4px]">
          <div class="flex">
            <Icon name="ruler" icon-width="w-[20px] h-[20px] " class="mr-[4px] relative top-[1px]" />
            <span class="text-sm text-purple-900">{{ area }}m<sup>2</sup></span>
          </div>
        </SearchProfileCardLabel>

        <SearchProfileCardLabel class="mr-[4px] mt-[4px]">
          <div class="flex">
            <Icon name="tag" icon-width="w-[20px] h-[20px] " class="mr-[4px] relative top-[2px]" />
            <span class="text-sm text-purple-900">{{ getCurrency() }}{{ rent }}</span>
          </div>
        </SearchProfileCardLabel>
      </div>
    </div>
  </component>
</template>
