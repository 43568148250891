<script setup>
import { usePage, Link } from '@inertiajs/vue3'
import HouseCardMatchHistory from '@js/Components/HouseCards/HouseCardMatchHistory/HouseCardMatchHistory.vue'
import Pagination from '@js/Components/Pagination/Pagination.vue'
import InfoMessageBox from '@js/Components/InfoMessageBox/InfoMessageBox.vue'
import Image from '@js/Components/Image/Image.vue'
import ScaredFace from '@images/icons/scared-face.png'
import MyRentbirdLayout from '@js/Components/MyRentbird/MyRentbirdLayout/MyRentbirdLayout.vue'
import { useUser } from '@js/App/Api/User/User'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import { ref, computed } from 'vue'
import sleepingBird from '@images/sleeping-bird.svg'
import sleepingBirdMail from '@images/sleeping-bird-mail.svg'

const { user } = useUser()
const page = usePage()
const houses = computed(() => page.props.houses?.data || [])
const baseUrl = getLocalizedUrl('my-rentbird.favorites')

const pageType = ref(null)
if (page.props.route.name === 'my-rentbird.favorites') {
  pageType.value = 'FAVORITES'
} else if (page.props.route.name === 'my-rentbird.reacted') {
  pageType.value = 'REACTED'
}
</script>

<template>
  <MyRentbirdLayout>
    <div class="flex items-center justify-between">
      <div class="bg-white rounded-t-lg pt-4 mt-4  w-full lg:w-1/2 lg:ml-4 xl:w-1/3">
        <div class="px-4 flex items-center justify-center text-center">
          <Link
            :href="$getLocalizedUrl('my-rentbird.favorites')"
            :class="{'w-1/2 block border-purple-500': true,
                     'border-b font-medium': pageType === 'FAVORITES'}"
          >
            {{ $trans('BookmarkPage.Favorites') }} ({{ pageType === 'FAVORITES' ? page.props.houses?.total : page.props.housesOtherTab }})
          </Link>
          <Link
            :href="$getLocalizedUrl('my-rentbird.reacted')"
            :class="{'w-1/2 block border-purple-500': true,
                     'border-b font-medium': pageType === 'REACTED'}"
          >
            {{ $trans('BookmarkPage.Reacted') }} ({{ pageType === 'REACTED' ? page.props.houses?.total : page.props.housesOtherTab }})
          </Link>
        </div>
      </div>
      <div class="hidden lg:flex">
        <Link :href="$getLocalizedUrl('my-rentbird.match-history')" class="text-blue-base">
          {{ $trans('BookmarkPage.AllMatches') }}
        </Link>
      </div>
    </div>
    <div class="bg-white px-4 pt-2 pb-4 lg:px-8 lg:py-8 lg:rounded-lg">
      <div class="pt-3 lg:pt-4">
        <InfoMessageBox v-if="!user.hasActiveSubscription" color="pink">
          <div class="flex items-start">
            <Image :src="ScaredFace" class="relative top-[3px] mr-[6px]" />
            <div
              class="text-base text-purple-900"
              v-html="$trans('MatchHistoryPage.FreeAccountMessage', { url: $getLocalizedUrl('upgrade') })"
            />
          </div>
        </InfoMessageBox>

        <div class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-3">
          <HouseCardMatchHistory
            v-for="item of houses"
            :key="item.house.random_id"
            :house-id="item.house.id"
            class="mb-[12px] lg:mb-[24px]"
            :name="item.house.name"
            :image="item.house.image_url"
            :url="item.house.click_track_url"
            :city="item.house.city"
            :bedrooms="item.house.bedrooms"
            :area="item.house.area"
            :rent="item.house.rent"
            :found-at-formatted="item.house.created_at_formatted_diff"
            :rental-agent="item.house.rental_agent_name"
            :show-viewed-label="true"
            :has-viewed="item.house.has_viewed"
            :is-historic="item.house.is_historic"
            :is-bookmarked="item.house.is_bookmarked"
            :is-reacted="item.house.is_reacted"
          />
        </div>
        <div v-if="pageType === 'FAVORITES' && houses?.length <= 0" class="flex flex-col mx-auto text-center items-center justify-center min-h-[500px] lg:min-h-[650px]">
          <img
            class="h-[143px] lg:h-[440px]"
            :src="sleepingBird"
            alt=""
          >
          <p class="mt-6 lg:text-2xl">
            {{ $trans('BookmarkPage.NoFavorites') }}
          </p>
        </div>
        <div v-if="pageType === 'REACTED' && houses?.length <= 0" class="flex flex-col mx-auto text-center items-center justify-center min-h-[500px] lg:min-h-[650px]">
          <img
            class="h-[143px] lg:h-[440px]"
            :src="sleepingBirdMail"
            alt=""
          >
          <p class="mt-6 lg:text-2xl">
            {{ $trans('BookmarkPage.NoReactions') }}
          </p>
        </div>

        <Pagination
          v-if="page.props.houses?.total > 9 || page.props.houses?.last_page > 2"
          class="mt-[32px]"
          :base-url="baseUrl"
          :current-page="page.props.houses?.current_page"
          :last-page="page.props.houses?.last_page"
        />
      </div>
    </div>
  </MyRentbirdLayout>
</template>
<style lang="scss">
.MyRentbird-BookmarkPage {
  background: rgb(234, 234, 235);
}
</style>
