<script setup>

import RenderPageSegment from '@js/Components/Blog/RenderPageSegment.vue'

defineProps({
  attributes: {
    type: Object,
    default: () => ({})
  },
  post: {
    type: Object,
    default: () => ({})
  }
})

const sizes = {
  auto: 'flex-grow ',
  '1/6': 'w-full lg:w-2/12 ',
  '2/6': 'w-full lg:w-4/12 ',
  '4/6': 'w-full lg:w-8/12 ',
  '5/6': 'w-full lg:w-10/12 ',
  '1/2': 'w-full lg:w-1/2 ',
  full: 'w-full'
}

const justification = {
  top: 'justify-top',
  center: 'justify-center',
  bottom: 'justify-bottom'
}
</script>

<template>
  <div class="flex flex-wrap justify-between lg:flex-nowrap gap-10">
    <div v-for="col in attributes.columns" :key="col.key" :class="`flex flex-col ${justification[col.attributes.align]} ${sizes[col.attributes.size]}`">
      <RenderPageSegment :post="post" :section="col" />
    </div>
  </div>
</template>
