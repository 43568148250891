<script setup>
import OffboardingLayout from '@js/Components/MyRentbird/Offboarding/OffboardingLayout/OffboardingLayout.vue'
import { computed, ref, watch, nextTick } from 'vue'
import { router } from '@inertiajs/vue3'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import OffboardingReasonInput
  from '@js/Components/MyRentbird/Offboarding/OffboardingReasonInput/OffboardingReasonInput.vue'
import OffboardingRatingInput
  from '@js/Components/MyRentbird/Offboarding/OffboardingRatingInput/OffboardingRatingInput.vue'
import {
  getStepAfterRating, STEP_CONFIRM_CANCEL,
  STEP_CONGRATULATIONS_WITH_YOUR_NEW_HOME,
  STEP_RATING,
  STEP_REASON, STEP_REASON_DETAILS,
  STEP_RENTBIRD_HOME_DEALS,
  STEP_RENTBIRD_HOME_DEALS_2,
  STEP_RENTBIRD_HOME_DEALS_3, STEP_RENTBIRD_HOME_DEALS_CONFIRMED,
  STEP_RENTBIRD_HOME_DEALS_VERIFY_YOUR_DETAILS, submitCancelSubscription, submitHomedealApplication, submitUserFeedback
} from '@js/App/Api/User/Offboarding'
import CongratulationsWithYourNewHome
  from '@js/Components/MyRentbird/Offboarding/CongratulationsWithYourNewHome/CongratulationsWithYourNewHome.vue'
import HomeDealsIntro from '@js/Components/MyRentbird/Offboarding/HomeDeals/HomeDealsIntro.vue'
import Container from '@js/Components/Container/Container.vue'
import HomeDealsSelectServices from '@js/Components/MyRentbird/Offboarding/HomeDeals/HomeDealsSelectServices.vue'
import HomeDealsContactInfo from '@js/Components/MyRentbird/Offboarding/HomeDeals/HomeDealsContactInfo.vue'
import { useUser } from '@js/App/Api/User/User'
import HomeDealsVerifyInfo from '@js/Components/MyRentbird/Offboarding/HomeDeals/HomeDealsVerifyInfo.vue'
import HomeDealsConfirmed from '@js/Components/MyRentbird/Offboarding/HomeDeals/HomeDealsConfirmed.vue'
import ConfirmCancel from '@js/Components/MyRentbird/Offboarding/ConfirmCancel/ConfirmCancel.vue'
import ReasonDetailsScreen from '@js/Components/MyRentbird/Offboarding/ReasonDetailsScreen/ReasonDetailsScreen.vue'
import { getQueryParameterBoolean } from '@js/App/Utils/ReadQueryParams'
import { trackCancelSubscriptionReason } from '@js/App/Tracking/Tracking'
import CustomTransitionFade from '@js/Components/Transitions/CustomTransitionFade.vue'
import OffboardingProgressBar
  from '@js/Components/MyRentbird/Offboarding/OffboardingProgressBar/OffboardingProgressBar.vue'
import { doPostRequest } from '@js/App/Api/Infra/ApiClient'

const { user } = useUser()

const step = ref(STEP_REASON)
const stepHistory = []
watch(step, (newValue, oldValue) => {
  stepHistory.push(oldValue)

  window.scrollTo({ top: 0, behavior: 'smooth' })
})
const stepNumber = computed(() => {
  if (step.value === STEP_REASON) {
    return 1
  }
  if (step.value === STEP_RATING) {
    return 2
  }
  if (step.value === STEP_CONGRATULATIONS_WITH_YOUR_NEW_HOME || step.value === STEP_REASON_DETAILS) {
    return 3
  }

  if (step.value === STEP_CONFIRM_CANCEL) {
    return 4
  }

  return 1
})

const reason = ref('')
const rating = ref('')
const message = ref('')

const userWantsToCancelWithFullRefund = getQueryParameterBoolean('cancelWithFullRefund', false)
const clickedReason = (reasonThatWasClicked) => {
  reason.value = reasonThatWasClicked
  step.value = STEP_RATING
}

const clickedRating = async (ratingThatWasClicked) => {
  rating.value = ratingThatWasClicked

  await submitUserFeedback(rating.value, reason.value, message.value)

  await trackCancelSubscriptionReason(reason.value)
  step.value = getStepAfterRating(reason.value, rating.value, user.value.hasAppliedForHomedeals)
}

const onClickedBack = () => {
  if (stepHistory.length <= 0) {
    router.visit(getLocalizedUrl('my-rentbird.subscription'))
    return
  }

  step.value = stepHistory.pop()

  // When clicking back, we want to remove the step from the history that is added because of the watcher
  nextTick(() => {
    stepHistory.pop()
  })
}

const showHomeDealsLogo = computed(() => false) // Updated: we no longer support homedeals here, never show.

const selectedHomedeals = ref([])
const homedealsContactInfo = ref({ phone: user.value.phone, email: user.value.email })

const errors = ref({})
</script>
<template>
  <OffboardingLayout
    :show-homedeals-logo="showHomeDealsLogo"
    :show-back-button="step !== STEP_REASON && step !== STEP_RENTBIRD_HOME_DEALS_CONFIRMED && step !== STEP_CONFIRM_CANCEL"
    @clicked:back="onClickedBack"
  >
    <div class="h-full text-white pb-[40px] relative">
      <container>
        <OffboardingProgressBar v-if="!showHomeDealsLogo" :step-number="stepNumber" class="mb-4 lg:mb-6 mt-4 lg:mt-20" />
      </container>
      <CustomTransitionFade :group="true" class="min-h-full bg-purple-700">
        <div
          v-if="step === STEP_REASON"
          :key="1"
          class=""
        >
          <Container>
            <OffboardingReasonInput
              :user-wants-to-cancel-with-full-refund="userWantsToCancelWithFullRefund"
              class="w-full"
              @clicked:reason="newReason => clickedReason(newReason)"
            />
          </Container>
        </div>
        <div
          v-if="step === STEP_RATING"
          :key="2"
          class=""
        >
          <Container>
            <OffboardingRatingInput
              class="w-full"
              @clicked:rating="newRating => clickedRating(newRating)"
            />
          </Container>
        </div>
        <div
          v-if="step === STEP_REASON_DETAILS"
          :key="3"
        >
          <Container>
            <ReasonDetailsScreen
              :reason="reason"
              :rating="rating"
              @clicked:next="step = STEP_CONFIRM_CANCEL"
            />
          </Container>
        </div>
        <div
          v-if="step === STEP_CONGRATULATIONS_WITH_YOUR_NEW_HOME"
          :key="4"
        >
          <Container>
            <CongratulationsWithYourNewHome
              class="w-full"
              @clicked:next="step = STEP_RENTBIRD_HOME_DEALS"
            />
          </Container>
        </div>
        <div
          v-if="step === STEP_RENTBIRD_HOME_DEALS"
          :key="5"
        >
          <HomeDealsIntro
            class="w-full"
            @clicked:homedeals="step = STEP_RENTBIRD_HOME_DEALS_2"
            @clicked:cancel="step = STEP_CONFIRM_CANCEL"
          />
        </div>
        <div
          v-if="step === STEP_RENTBIRD_HOME_DEALS_2"
          :key="6"
          class=""
        >
          <Container class="bg-purple-700">
            <HomeDealsSelectServices
              v-model="selectedHomedeals"
              class="w-full"
              @clicked:next="() => {
                if (selectedHomedeals.length > 0) {
                  step = STEP_RENTBIRD_HOME_DEALS_3
                }
              }"
            />
          </Container>
        </div>
        <div
          v-if="step === STEP_RENTBIRD_HOME_DEALS_3"
          :key="7"
          class=""
        >
          <Container class="bg-purple-700">
            <HomeDealsContactInfo
              v-model="homedealsContactInfo"
              @clicked:next="step = STEP_RENTBIRD_HOME_DEALS_VERIFY_YOUR_DETAILS"
            />
          </Container>
        </div>
        <div
          v-if="step === STEP_RENTBIRD_HOME_DEALS_VERIFY_YOUR_DETAILS"
          :key="8"
        >
          <Container>
            <HomeDealsVerifyInfo
              :contact-info="homedealsContactInfo"
              :selected-homedeals="selectedHomedeals"
              @clicked:next="async () => {
                await submitHomedealApplication(homedealsContactInfo, selectedHomedeals)
                step = STEP_RENTBIRD_HOME_DEALS_CONFIRMED
              }"
              @clicked:back="onClickedBack"
              @clicked:goto-services="() => {
                step = STEP_RENTBIRD_HOME_DEALS_2
                nextTick(() => {
                  stepHistory.pop();
                  stepHistory.pop();
                  stepHistory.pop();
                })
              }"
            />
          </Container>
        </div>
        <div
          v-if="step === STEP_RENTBIRD_HOME_DEALS_CONFIRMED"
          :key="9"
          class=""
        >
          <Container>
            <HomeDealsConfirmed
              @clicked:next="() => {
                step = STEP_CONFIRM_CANCEL
              }"
            />
          </Container>
        </div>
        <div
          v-if="step === STEP_CONFIRM_CANCEL"
          :key="10"
          class=""
        >
          <Container>
            <ConfirmCancel
              :user-wants-to-cancel-with-full-refund="userWantsToCancelWithFullRefund"
              :user-has-apple-in-app-payment="user.lastPaymentIsAppleInApp"
              :errors="errors"
              @clicked:cancel-subscription="shouldDeleteAllSearchProfiles => {
                errors = {}
                submitCancelSubscription(shouldDeleteAllSearchProfiles, userWantsToCancelWithFullRefund, user.lastPaymentIsAppleInApp)
                  .then((result) => {
                    if (result.hasErrors) {
                      errors = result.errors
                      return
                    }

                    const queryParamsToAdd = {}
                    if (userWantsToCancelWithFullRefund) {
                      queryParamsToAdd.cancelWithFullRefund = userWantsToCancelWithFullRefund
                    }
                    router.visit(getLocalizedUrl('my-rentbird.cancellation-confirmed', {}, queryParamsToAdd))
                  })
              }"
              @clicked:go-back="async () => {
                await doPostRequest('/api/user/subscription', {
                  auto_renew: true
                })
                router.visit(getLocalizedUrl('my-rentbird.subscription'))
              }"
            />
          </Container>
        </div>
      </CustomTransitionFade>
    </div>
  </OffboardingLayout>
</template>
<style lang="scss">
.MyRentbird-OffboardingPage {
  background-color: rgb(40,33,75);
}
</style>
