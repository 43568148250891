<script setup>

const props = defineProps({
  attributes: {
    type: Object,
    default: () => ({})
  },
  id: {
    type: String,
    default: ''
  }
})

const { title, video_id: videoID } = props.attributes

</script>

<template>
  <iframe
    :id="id"
    :class="{
      'w-full rounded-lg': true,
      'aspect-video': !attributes.vertical,
      'aspect-[9/16]': attributes.vertical
    }"
    :src="`https://www.youtube.com/embed/${videoID}?enablejsapi=1&origin=https%3A%2F%2Frentbird.nl`"
    :title="title"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen="allowfullscreen"
  />
</template>
