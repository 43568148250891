<script setup>
import B2BLayout from '@js/Components/B2B/B2BLayout.vue'
import { computed, ref } from 'vue'
import { router, usePage } from '@inertiajs/vue3'
import MyRentbirdCard from '@js/Components/MyRentbird/MyRentbirdCard/MyRentbirdCard.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import NeighbourhoodSelectUsingStore from '@js/Components/Forms/NeighbourhoodSelect/NeighbourhoodSelectUsingStore.vue'
import MinBedroomsSelect from '@js/Components/Forms/MinBedroomsSelect/MinBedroomsSelect.vue'
import MinRentSelect from '@js/Components/Forms/MinRentSelect/MinRentSelect.vue'
import NumberOfMatchesBoxUseCurrentSearchProfile
  from '@js/Components/NumberOfMatchesBox/NumberOfMatchesBoxCurrentSearchProfile.vue'
import CitySelectUsingStore from '@js/Components/Forms/CitySelect/CitySelectUsingStore.vue'
import SearchProfileCardDistanceOrNeighbourhoodsLabel
  from '@js/Components/SearchProfileCard/SearchProfileCardDistanceOrNeighbourhoodsLabel.vue'
import MaxRentSelect from '@js/Components/Forms/MaxRentSelect/MaxRentSelect.vue'
import SearchProfileStickyBottom
  from '@js/Components/MyRentbird/SearchProfileStickyBottom/SearchProfileStickyBottom.vue'
import CityMap from '@js/Components/SearchProfileWizards/SearchProfileWizard/CityMap.vue'
import DistanceSelect from '@js/Components/Forms/DistanceSelect/DistanceSelect.vue'
import FurnishedSelect from '@js/Components/Forms/FurnishedSelect/FurnishedSelect.vue'
import DarkPurpleThinModal from '@js/Components/Modals/DarkPurpleThinModal.vue'
import MinSurfaceSelect from '@js/Components/Forms/MinSurfaceSelect/MinSurfaceSelect.vue'
import ErrorMessage from '@js/Components/Forms/ErrorMessage/ErrorMessage.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import { getSearchProfileDto, useSearchProfileStore } from '@js/App/Stores/SearchProfileStore'
import { storeToRefs } from 'pinia'
import { useMainStore } from '@js/App/Stores/MainStore'
import { useMatchesPerWeekAndStoreResultsInPinia } from '@js/App/Api/MatchesPerWeek'
import { deleteB2BSearchProfile, saveB2BSearchProfile } from '@js/App/Api/SearchProfile'
import { trans } from '@js/App/Plugins/Translations'
import { useIsMobile } from '@js/App/Utils/BrowserDetection'
import B2BExtraWishesCheckboxGroup from '@js/Components/Forms/ExtraWishesCheckboxGroup/B2BExtraWishesCheckboxGroup.vue'
import { useB2BOrganization } from '@js/App/Api/B2B/B2B'
import CustomTransitionFade from '@js/Components/Transitions/CustomTransitionFade.vue'
import { ziggyRoute } from '@js/App/Utils/ZiggyUrls'
import LivingRequirementsCheckboxGroup
  from '@js/Components/Forms/LivingRequirementsCheckboxGroup/LivingRequirementsCheckboxGroup.vue'
import HouseRequirementsNotClearCheckbox
  from '@js/Components/Forms/EnergyLabelCheckbox/HouseRequirementsNotClearCheckbox.vue'

const page = usePage()
const user = computed(() => page.props.b2b_user)

const searchProfileStore = useSearchProfileStore()
const { searchProfile } = storeToRefs(searchProfileStore)
const mainStore = useMainStore()

// We are going to set this to false, because otherwise we will be overridden by the StoresSetup.js reset methods
searchProfileStore.shouldResetOnNavigation = false
setTimeout(() => { searchProfileStore.shouldResetOnNavigation = true }, 3000)
searchProfileStore.setSearchProfileFromDtoObject(page.props.search_profile)

const errors = ref({})

useMatchesPerWeekAndStoreResultsInPinia()

const showSuccessfullySavedMessage = ref(false)

const onClickedSave = async () => {
  mainStore.startLoading(false)

  const dto = getSearchProfileDto(searchProfile.value)
  const { errors: apiErrors } = await saveB2BSearchProfile(dto, user.value.random_id)
  errors.value = apiErrors

  if (Object.keys(errors.value).length <= 0) {
    showSuccessfullySavedMessage.value = true
    mainStore.successMessageText = trans('EditSearchProfilePage.SaveCompleted')
    setTimeout(() => { showSuccessfullySavedMessage.value = false }, 3500)
  }

  mainStore.stopLoading()
}

const { organization } = useB2BOrganization()

const onClickedDelete = async () => {
  mainStore.startLoading(false)
  // If no server ID is set, we haven't yet created anything. Just navigate back.
  if (searchProfile.value.serverId) {
    await deleteB2BSearchProfile(searchProfile.value.serverId, user.value.random_id)
  }
  router.visit(
    ziggyRoute('b2b.user.details', { organization: organization.value, user: user.value.random_id })
  )
  mainStore.stopLoading()
}

const { isMobile } = useIsMobile()

const confirmDeleteModalIsOpen = ref(false)

</script>
<template>
  <B2BLayout
    :bread-crumbs="[
      {
        label: 'Users',
        url: ziggyRoute('b2b.home', { organization: organization})
      },
      {
        label: `${user.firstname} ${user.lastname}`,
        url: ziggyRoute('b2b.user.details', { organization: organization, user: user.random_id})
      },
      {
        label: 'Edit search',
        url: '',
      }
    ]"
  >
    <template #title>
      Edit search for {{ user.firstname }}
    </template>

    <MyRentbirdCard
      class="mt-[16px]"
      :can-expand-on-mobile="false"
    >
      <template #title>
        <div class="flex items-center">
          {{ searchProfile.city?.name || $trans('CreateSearchProfile.YourSearchProfile') }}
          <Icon
            v-tippy="{
              content: searchProfile.serverId ? $trans('Common.Active') : $trans('Common.Inactive'),
              allowHTML: true,
            }"
            class="pl-[8px]"
            :name="searchProfile.serverId ? 'dot-green' : 'dot-red'"
          />
        </div>
      </template>
      <template #title-desktop-right>
        <Button :outline="true" :small="true" icon-left="trash" @click="confirmDeleteModalIsOpen = true">
          <span>{{ $trans('Common.Delete') }}</span>
        </Button>
      </template>
      <template #default>
        <SearchProfileCardDistanceOrNeighbourhoodsLabel
          class="lg:relative lg:top-[-8px] lg:mb-[-20px]"
          :search-profile="searchProfile"
          :big-text-on-desktop="true"
        />

        <div class="flex mt-[8px] lg:hidden">
          <Button :outline="true" :small="true" icon-left="trash" @click="confirmDeleteModalIsOpen = true">
            <span>{{ $trans('Common.Delete') }}</span>
          </Button>
        </div>

        <NumberOfMatchesBoxUseCurrentSearchProfile class="mt-5" />

        <div class="lg:flex">
          <div class="lg:flex-grow">
            <div class=" lg:pr-[24px] lg:flex">
              <div class="lg:w-1/2 lg:mr-[8px]">
                <CitySelectUsingStore
                  :text-input-margin-right="false"
                  :error="errors.city"
                  class="mt-4"
                  :black="true"
                />
                <NeighbourhoodSelectUsingStore
                  v-show="isMobile && searchProfile.locationSelector === 'neighbourhoods'"
                  class="mt-4"
                  :error="errors.neighbourhoods"
                  @click:distance="searchProfile.locationSelector = 'distance'"
                />
                <DistanceSelect
                  v-if="isMobile && searchProfile.locationSelector === 'distance'"
                  class="mt-4 lg:min-w-1/2"
                  :error="errors.distance"
                  @click:neighbourhoods="searchProfile.locationSelector = 'neighbourhoods'"
                />

                <MinRentSelect v-if="!isMobile" :error="errors.min_rent" />

                <MinBedroomsSelect v-if="!isMobile" :error="errors.min_bedrooms" class="mt-4" />

                <CityMap v-if="isMobile" class="w-full h-[300px] mt-[8px]" />
              </div>
              <div class="lg:w-1/2 lg:ml-[8px]">
                <NeighbourhoodSelectUsingStore
                  v-show="!isMobile && searchProfile.locationSelector === 'neighbourhoods'"
                  class="mt-4"
                  :error="errors.neighbourhoods"
                  @click:distance="searchProfile.locationSelector = 'distance'"
                />
                <DistanceSelect
                  v-if="!isMobile && searchProfile.locationSelector === 'distance'"
                  class="mt-4 lg:min-w-1/2"
                  :error="errors.distance"
                  @click:neighbourhoods="searchProfile.locationSelector = 'neighbourhoods'"
                />

                <MinRentSelect v-if="isMobile" :error="errors.min_rent" />

                <MaxRentSelect :error="errors.max_rent" class="mt-4" />

                <MinBedroomsSelect v-if="isMobile" :error="errors.min_bedrooms" class="mt-4" />

                <MinSurfaceSelect :error="errors.min_surface" class="mt-4" />
              </div>
            </div>
            <FurnishedSelect class="mt-4 lg:mr-[24px]" :error="errors.furnished" />
          </div>
          <div>
            <CityMap v-if="!isMobile" class="w-[450px] h-[430px] mt-[20px]" />
          </div>
        </div>

        <div class="mt-4 lg:w-1/2 lg:mr-[12px]">
          <LivingRequirementsCheckboxGroup :can-collapse="false" />

          <HouseRequirementsNotClearCheckbox class="mt-4" />
        </div>

        <div class="mt-12 lg:w-1/2 lg:mr-[12px]">
          <B2BExtraWishesCheckboxGroup />
        </div>

        <ErrorMessage v-if="errors.general" class="hidden lg:block mt-[4px]">
          {{ errors.general }}
        </ErrorMessage>

        <div class="hidden lg:flex lg:items-center lg:mt-4">
          <Button
            :icon-right="showSuccessfullySavedMessage ? 'check' : 'save'"
            :full-width-on-mobile="true"
            @click="onClickedSave"
          >
            <span v-if="!showSuccessfullySavedMessage">{{ $trans('Common.Save') }}</span>
            <span v-else>{{ $trans('Common.Saved') }}</span>
          </Button>
        </div>
      </template>
    </MyRentbirdCard>

    <div
      class="text-xs text-gray-600 text-center mt-4 pb-[70px] lg:hidden"
      v-html="$trans('EditSearchProfilePage.Disclaimer')"
    />

    <SearchProfileStickyBottom
      class="lg:hidden"
      :show-successfully-saved-message="showSuccessfullySavedMessage"
      @click="onClickedSave"
    >
      <ErrorMessage v-if="errors.general" class="mt-[6px]">
        {{ errors.general }}
      </ErrorMessage>
    </SearchProfileStickyBottom>

    <CustomTransitionFade>
      <DarkPurpleThinModal v-show="confirmDeleteModalIsOpen" :show-top-bar="false">
        <h4 class="text-white text-xl font-medium">
          {{ $trans('EditSearchProfilePage.DeleteTitle') }}
        </h4>
        <p class="mt-[6px] text-base">
          {{ $trans('EditSearchProfilePage.DeleteContent') }}
        </p>
        <Button
          :full-width-on-mobile="true"
          :full-width-on-desktop="true"
          icon-right="trash"
          class="mt-[32px]"
          @click="onClickedDelete"
        >
          <span v-html="$trans('EditSearchProfilePage.DeleteConfirm', { name: searchProfile.city?.name || $trans('CreateSearchProfile.YourSearchProfile') })" />
        </Button>
        <Button
          :full-width-on-mobile="true"
          :full-width-on-desktop="true"
          icon-right="back"
          class="mt-[8px]"
          :outline="true"
          @click="confirmDeleteModalIsOpen = false"
        >
          <span v-html="$trans('EditSearchProfilePage.CancelDelete')" />
        </Button>
      </DarkPurpleThinModal>
    </CustomTransitionFade>
  </B2BLayout>
</template>
<style lang="scss">
.B2B-EditSearchPage {
  background: rgb(234,234,235);
}
</style>
