<script setup>
import { computed, ref } from 'vue'
import { router } from '@inertiajs/vue3'
import { CREATE_RENTBIRD_PLUS_ACCOUNT_MODAL_ID } from '@js/App/Plugins/Modals'
import SmallLabel from '@js/Components/SmallLabel/SmallLabel.vue'
import RentbirdPlusLogo from '@js/Components/Logos/RentbirdPlusLogo/RentbirdPlusLogo.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import WizardWithSlidingSteps from '@js/Components/Forms/WizardWithSlidingSteps/WizardWithSlidingSteps.vue'
import CityMap from '@js/Components/SearchProfileWizards/SearchProfileWizard/CityMap.vue'
import StepCityAndNeighbourhood
  from '@js/Components/SearchProfileWizards/RentbirdPlusWizard/StepCityAndNeighbourhood.vue'
import WizardNextStepButton from '@js/Components/SearchProfileWizards/SearchProfileWizard/WizardNextStepButton.vue'
import {
  isEligbleForRentbirdPlus,
  submitStep1,
  submitStep2,
  submitStep3,
  submitStep4
} from '@js/App/Api/RentbirdPlus'
import StepHouseDetails from '@js/Components/SearchProfileWizards/RentbirdPlusWizard/StepHouseDetails.vue'
import StepIncome from '@js/Components/SearchProfileWizards/RentbirdPlusWizard/StepIncome.vue'
import StepPersonalDetails from '@js/Components/SearchProfileWizards/RentbirdPlusWizard/StepPersonalDetails.vue'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import { storeToRefs } from 'pinia'
import { useSearchProfileStore } from '@js/App/Stores/SearchProfileStore'
import { rentbirdPlusQualify } from '@js/App/Utils/RentbirdPlusQualify'

const step = ref(1)
const errors = ref({})
const waitForFinalRequest = ref(false)
const { searchProfile } = storeToRefs(useSearchProfileStore())

if (router?.page?.props?.route?.name === 'rentbird-plus-guarantee') {
  searchProfile.value.rentbirdPlusProposition = 'woninggarantie'
  searchProfile.value.rentbirdPlusPrice = 1295
}

const clickedNextStep = async () => {
  if (step.value === 1) {
    const { errors: apiErrors, hasErrors } = await submitStep1()
    if (hasErrors) {
      errors.value = apiErrors
      return
    }

    step.value++
    return
  }

  if (step.value === 2) {
    const { errors: apiErrors, hasErrors } = await submitStep2()
    if (hasErrors) {
      errors.value = apiErrors
      return
    }

    step.value++
    return
  }

  if (step.value === 3) {
    const { errors: apiErrors, hasErrors } = await submitStep3()
    if (hasErrors) {
      errors.value = apiErrors
      return
    }

    step.value++
    return
  }

  if (step.value === 4) {
    waitForFinalRequest.value = true
    const { errors: apiErrors, hasErrors } = await submitStep4()
    waitForFinalRequest.value = false
    if (hasErrors) {
      errors.value = apiErrors
      return
    }

    step.value++
  }

  if (step.value === 5) {
    // rules of https://www.notion.so/rentbirds/2e-versie-Rentbird-Plus-pagina-bedankpagina-s-1b4a42f502a880c8b170f6e8841ed000?pvs=4
    if (rentbirdPlusQualify(searchProfile.value) &&
    (['loondienst', 'expat', 'ondernemer'].includes(searchProfile.value.rentbirdPlusCurrentJob) || searchProfile.value.rentbirdPlusIHaveMoreThan50K) &&
  (parseInt(searchProfile.value.rentbirdPlusIncome ?? 0) + parseInt(searchProfile.value.rentbirdPlusPartnerIncome ?? 0) >= parseInt(searchProfile.value.maxRent ?? 0) * 3) &&
  searchProfile.value.rentbirdPlusCountry === 'NL') {
      if (searchProfile.value.rentbirdPlusProposition === 'woninggarantie') {
        router.visit(getLocalizedUrl('rentbird-plus-guarantee-thank-you-q'))
      } else {
        router.visit(getLocalizedUrl('rentbird-plus-thank-you-q'))
      }
    } else if (searchProfile.value.rentbirdPlusProposition === 'woninggarantie') {
      router.visit(getLocalizedUrl('rentbird-plus-guarantee-thank-you-uq'))
    } else {
      router.visit(getLocalizedUrl('rentbird-plus-thank-you-uq'))
    }
  }
}

const clickedPreviousStep = () => {
  step.value--
}

const nextStepDisabled = computed(() => !isEligbleForRentbirdPlus())
</script>
<template>
  <div
    class="flex-col flex justify-between min-h-full"
    :class="{'h-full': step === 1}"
  >
    <div class="h-full flex-grow flex flex-col py-4 lg:py-6">
      <div class="flex justify-between items-center px-4 lg:px-6 mb-[24px] lg:mb-[24px]">
        <SmallLabel class="!bg-[#E79F35]">
          {{ $trans('SearchProfileWizard.Step', { step: Math.min(4, step), total: 4 }) }}
        </SmallLabel>
        <RentbirdPlusLogo :href="false" class=" !w-[118px] !lg:w-[128px]" />
        <Icon
          name="cross"
          class="text-purple-700 hover:text-pink-500 transition-colors hover:cursor-pointer"
          @click="$closeModal(CREATE_RENTBIRD_PLUS_ACCOUNT_MODAL_ID)"
        />
      </div>

      <WizardWithSlidingSteps
        :steps="5"
        :step="step"
        class="flex flex-col flex-grow "
      >
        <template #step-1>
          <div class="flex flex-col h-full lg:flex-row bg-white px-4 lg:px-6">
            <div class="lg:w-1/2 lg:pr-4">
              <StepCityAndNeighbourhood :errors="errors" />
            </div>

            <CityMap class="mt-4 mb-0 lg:mb-0 lg:w-1/2 lg:ml-4 flex-grow" />
          </div>

          <div class="px-4 lg:px-6">
            <WizardNextStepButton
              :next-button-id="`request-free-intake-step-${step}`"
              :show-previous-button="false"
              class="pt-4 lg:pt-8 pb-4"
              :error="errors.general"
              @click:next-step="clickedNextStep"
              @click:previous-step="clickedPreviousStep"
            >
              {{ $trans('SearchProfileWizard.NextButtonLabel') }}
            </WizardNextStepButton>
          </div>
        </template>
        <template #step-2>
          <div class="flex-col flex flex-grow justify-between min-h-full bg-white px-4 lg:px-6">
            <div>
              <StepHouseDetails :errors="errors" />
            </div>

            <div class="">
              <WizardNextStepButton
                :next-button-id="`request-free-intake-step-${step}`"
                :show-previous-button="true"
                class="pt-4 lg:pt-8 pb-4"
                :error="errors.general"
                :next-step-disabled="nextStepDisabled"
                @click:next-step="clickedNextStep"
                @click:previous-step="clickedPreviousStep"
              >
                {{ $trans('SearchProfileWizard.NextButtonLabel') }}
              </WizardNextStepButton>
            </div>
          </div>
        </template>
        <template #step-3>
          <div class="flex-col flex flex-grow justify-between min-h-full bg-white px-4 lg:px-6">
            <div>
              <StepIncome :errors="errors" />
            </div>

            <div class="">
              <WizardNextStepButton
                :next-button-id="`request-free-intake-step-${step}`"
                :show-previous-button="true"
                class="pt-4 lg:pt-8 pb-4"
                :error="errors.general"
                @click:next-step="clickedNextStep"
                @click:previous-step="clickedPreviousStep"
              >
                {{ $trans('SearchProfileWizard.NextButtonLabel') }}
              </WizardNextStepButton>
            </div>
          </div>
        </template>
        <template #step-4>
          <div class="flex-col flex flex-grow justify-between min-h-full bg-white px-4 lg:px-6">
            <div>
              <StepPersonalDetails :errors="errors" />
            </div>

            <div class="">
              <WizardNextStepButton
                :next-button-id="`request-free-intake-step-${step}`"
                :show-previous-button="true"
                class="pt-4 lg:pt-8 pb-4"
                :error="errors.general"
                :next-step-disabled="waitForFinalRequest"
                @click:next-step="clickedNextStep"
                @click:previous-step="clickedPreviousStep"
              >
                {{ $trans('SearchProfileWizard.NextButtonLabel') }}
              </WizardNextStepButton>
            </div>
          </div>
        </template>
      </WizardWithSlidingSteps>
    </div>
  </div>
</template>
