import { trans } from '@js/App/Plugins/Translations'

export const getHardcodedReviews = () => {
  return [
    {
      name: 'Anne Mens',
      formatted_title_text: trans('UserReviews.AnneMensTitle'),
      stars: 5,
      trustpilot_id: '6798cbe897862e3ed7916517',
      image: '/img/people/user-review-anne.jpeg',
      video: '/videos/user-review-anne.mov',
      date_formatted: trans('UserReviews.AnneMensDate'),
      formatted_review_text: trans('UserReviews.AnneMensContent')
    },
    {
      name: 'Quentin Oliekan',
      formatted_title_text: trans('UserReviews.QuentinOliekanTitle'),
      stars: 5,
      trustpilot_id: '67d93355cce86bff880862ec',
      image: '/img/people/user-review-quentin.jpg',
      date_formatted: trans('UserReviews.QuentinOliekanDate'),
      formatted_review_text: trans('UserReviews.QuentinOliekanContent')
    },
    {
      name: 'Eliza Starrenburg',
      formatted_title_text: trans('UserReviews.ElizaStarrenburgTitle'),
      stars: 5,
      trustpilot_id: '677fa6269d8756824532e1d8',
      image: '/img/people/user-review-eliza.jpg',
      date_formatted: trans('UserReviews.ElizaStarrenburgDate'),
      formatted_review_text: trans('UserReviews.ElizaStarrenburgContent')
    },
    {
      name: 'Rodolfo Redlich',
      formatted_title_text: trans('UserReviews.RodolfoRedlichTitle'),
      stars: 5,
      trustpilot_id: '67d74927806dcab76f18680a',
      image: '/img/people/user-review-rodolfo.jpg',
      date_formatted: trans('UserReviews.RodolfoRedlichDate'),
      formatted_review_text: trans('UserReviews.RodolfoRedlichContent')
    },
    {
      name: 'Vanessa Bertoletti',
      formatted_title_text: trans('UserReviews.VanessaBertolettiTitle'),
      stars: 5,
      trustpilot_id: '67d85c957070b83eff15eed5',
      image: '/img/people/user-review-vanessa.jpg',
      date_formatted: trans('UserReviews.VanessaBertolettiDate'),
      formatted_review_text: trans('UserReviews.VanessaBertolettiContent')
    },
    {
      name: 'Remco Troquete',
      formatted_title_text: trans('UserReviews.RemcoTroqueteTitle'),
      stars: 5,
      trustpilot_id: '6745e6f958a4e05b397fac29',
      image: '/img/people/user-review-remco.jpg',
      date_formatted: trans('UserReviews.RemcoTroqueteDate'),
      formatted_review_text: trans('UserReviews.RemcoTroqueteContent')
    },
    {
      name: 'Britt Wilke',
      formatted_title_text: trans('UserReviews.BrittWilkeTitle'),
      stars: 5,
      trustpilot_id: '674712354c7bf08b64e83534',
      image: '/img/people/user-review-britt.jpg',
      date_formatted: trans('UserReviews.BrittWilkeDate'),
      formatted_review_text: trans('UserReviews.BrittWilkeContent')
    },
    {
      name: 'Noa Hoekstra',
      formatted_title_text: trans('UserReviews.NoaHoekstraTitle'),
      stars: 5,
      trustpilot_id: '65c4c1a4dbf092ece2004583',
      image: '/img/people/user-review-noah.png',
      video: '/videos/user-review-noah.mov',
      date_formatted: trans('UserReviews.NoaHoekstraDate'),
      formatted_review_text: trans('UserReviews.NoaHoekstraContent')
    }
  ]
}
