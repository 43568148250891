<script setup>
import Icon from '@js/Components/Icon/Icon.vue'
import { useUser } from '@js/App/Api/User/User'
const { user, userActivePayment } = useUser()

defineProps({
  isActive: {
    type: Boolean,
    default: false
  },
  index: {
    type: Number,
    default: 0
  },
  price: {
    type: Object,
    default: () => ({})
  },
  theme: {
    type: String,
    default: 'dark'
  }
})
</script>
<template>
  <div
    :class="{
      'transition-colors relative rounded-lg text-white hover:cursor-pointer px-3 py-4 my-1 border-[0.5px]' : true,
      'text-white border border-gray-400': !isActive,
      'text-purple-900 bg-purple-500 border border-white': isActive && theme === 'dark',
      'bg-blue-50': isActive && theme === 'light',
      'hover:bg-purple-500': theme === 'dark',
      'hover:bg-blue-50': theme === 'light'
    }"
  >
    <div v-if="index===0 && (!user.isLoggedIn || userActivePayment === null)" class="absolute top-[-15%] left-[0] w-full text-center lg:hidden">
      <span class="bg-blue-base text-xs px-4 py-0.5 rounded-lg">
        {{ $trans('PricingCardSimpleButton.Label') }}
      </span>
    </div>
    <div v-if="user.isLoggedIn && price && userActivePayment && price.plan === userActivePayment.plan" class="absolute top-[-15%] left-[0] w-full text-center lg:hidden">
      <span class="bg-blue-base text-xs px-4 py-0.5 rounded-lg">
        {{ $trans('PricingCardSimpleButton.LabelUser') }}
      </span>
    </div>

    <div class="flex flex-row justify-between items-center">
      <div class="flex flex-row items-center">
        <Icon
          class="mr-[6px] lg:mr-2"
          :name="isActive? 'circle-checked-blue-white' : 'circle'"
          icon-width="w-[20px] h-[20px] md:h-[24px]"
        />
        <div class="lg:text-xl font-bold mr-2 lg:mr-4 whitespace-nowrap md:whitespace-normal flex items-center">
          <slot name="title" />
        </div>
      </div>
      <div class="flex flex-row items-center text-right">
        <div class="text-sm xl:text-base lowercase mr-1 whitespace-nowrap md:whitespace-normal">
          <slot name="price" />
        </div>
      </div>
    </div>
  </div>
</template>
