import { doPostRequest, doDeleteRequest } from '@js/App/Api/Infra/ApiClient'
import { useMainStore } from '@js/App/Stores/MainStore'
import { isEmpty } from '@js/App/Utils/IsEmpty'
import { reloadPropsFromServer } from '@js/App/Api/Infra/ServerData'

export const bookmarkHouse = async (houseId) => {
  const mainStore = useMainStore()
  mainStore.startLoading()

  try {
    const response = await doPostRequest('/api/bookmark', {
      house_id: houseId
    })

    return {
      hasErrors: !isEmpty(response.errors),
      errors: response.errors
    }
  } finally {
    mainStore.stopLoading()
    reloadPropsFromServer()
  }
}

export const unbookmarkHouse = async (houseId) => {
  const mainStore = useMainStore()
  mainStore.startLoading()

  try {
    const response = await doDeleteRequest(`/api/bookmark/house/${houseId}`)

    return {
      hasErrors: !isEmpty(response.errors),
      errors: response.errors
    }
  } finally {
    mainStore.stopLoading()
    reloadPropsFromServer()
  }
}

export const reactToHouse = async (houseId) => {
  const mainStore = useMainStore()
  mainStore.startLoading()

  try {
    const response = await doPostRequest('/api/reaction', {
      house_id: houseId
    })

    return {
      hasErrors: !isEmpty(response.errors),
      errors: response.errors
    }
  } finally {
    mainStore.stopLoading()
    reloadPropsFromServer()
  }
}

export const unreactToHouse = async (houseId) => {
  const mainStore = useMainStore()
  mainStore.startLoading()

  try {
    const response = await doDeleteRequest(`/api/reaction/house/${houseId}`)

    return {
      hasErrors: !isEmpty(response.errors),
      errors: response.errors
    }
  } finally {
    mainStore.stopLoading()
    reloadPropsFromServer()
  }
}
